<template>
    <a-row class="event-content-properties">
        <a-col :span="24" class="input-area-wrapper">
            <label class="input-label">퀴즈 정답</label>
            <a-textarea
                class="input-area"
                v-model:value="valueRef.quizAnswer"
            />
        </a-col>
        <a-col :span="24" class="input-area-wrapper">
            <label class="input-label">퀴즈 코멘트</label>
            <a-textarea
                class="input-area"
                placeholder="정답 부분에 %s를 넣어주세요!"
                v-model:value="valueRef.quizCommentFormat"
            />
        </a-col>
    </a-row>
</template>

<style>
.event-content-properties {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
}
.input-label {
    font-weight: bold;
}
.input-area-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
</style>
<script setup lang="ts">
import { toRef } from 'vue'

const props = defineProps({
    value: {
        type: Object,
        default: () => {},
    },
})

const valueRef = toRef(props, 'value')

console.log('properties input : ', valueRef)
</script>
