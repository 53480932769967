<template>
    <a-layout id="app-layout-fold-trigger">
        <a-layout-sider
            id="side"
            breakpoint="lg"
            v-model:collapsed="collapsed"
            :trigger="null"
            collapsed-width="100"
            collapsible
        >
            <div class="logo">
                <img src="img/icons/apple-icon-72x72.png" />
            </div>
            <a-menu
                id="side__menu"
                theme="light"
                mode="inline"
                :default-selected-keys="currentMenus"
            >
                <a-menu-item
                    v-if="authorities.includes(Role.RESEARCHER)"
                    key="/home"
                >
                    <home-outlined />
                    <span>Home</span>
                    <router-link to="/" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.RESEARCHER)"
                    key="/userFeedback"
                >
                    <span class="anticon emoji-icon"> 📝 </span>
                    <span>사용자 피드백</span>
                    <router-link to="/userFeedback" />
                </a-menu-item>
                <a-sub-menu key="/productGroup" title="제품 & 이미지">
                    <template #icon>
                        <span class="emoji-icon anticon">💊</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.RESEARCHER)"
                        key="/products"
                    >
                        <span class="emoji-icon anticon">💊</span>
                        <span>영양제</span>
                        <router-link to="/products" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.RESEARCHER)"
                        key="/brand"
                    >
                        <span class="emoji-icon anticon">🏷️</span>
                        <span>브랜드</span>
                        <router-link to="/brand" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/productTag"
                    >
                        <span class="emoji-icon anticon">📛</span>
                        <span>제품 태그</span>
                        <router-link to="/productTag" />
                    </a-menu-item>
                    <a-menu-item key="/fileManager">
                        <span class="emoji-icon anticon">📁</span>
                        <span>이미지 파일 업로더</span>
                        <router-link to="/fileManager" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/nutrientGroup"
                    title="영양소 & 개인화"
                >
                    <template #icon>
                        <span class="emoji-icon anticon">🍎</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/nutrient"
                    >
                        <span class="emoji-icon anticon">🍎</span>
                        <span>영양소</span>
                        <router-link to="/nutrient" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/nutrientGroup"
                    >
                        <span class="emoji-icon anticon">🌳</span>
                        <span>영양소 그룹</span>
                        <router-link to="/nutrientGroup" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/nutrientAllowanceGroup"
                    >
                        <span class="emoji-icon anticon">🥗</span>
                        <span>영양소 섭취량 그룹</span>
                        <router-link to="/nutrientAllowanceGroup" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/nutrientAllowance"
                    >
                        <span class="emoji-icon anticon">⚖️</span>
                        <span>섭취량</span>
                        <router-link to="/nutrientAllowance" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/nutrientGoodPoint"
                    >
                        <span class="emoji-icon anticon">👍</span>
                        <span>영양소 좋은점</span>
                        <router-link to="/nutrientGoodPoint" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/standardUnit"
                    >
                        <span class="emoji-icon anticon">📏</span>
                        <span>영양소 단위</span>
                        <router-link to="/standardUnit" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/hig"
                    >
                        <span class="emoji-icon anticon">🎯</span>
                        <span> HIG (건강목표) </span>
                        <router-link to="/hig" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/intakeAdvisor"
                    >
                        <span class="emoji-icon anticon">🚦</span>
                        <span> 신호등 Flag </span>
                        <router-link to="/intakeAdvisor" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/healthStatus"
                    >
                        <span class="emoji-icon anticon">📊</span>
                        <span> 건강 상태 </span>
                        <router-link to="/healthStatus" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/ad"
                    title="디스플레이 광고"
                >
                    <template #icon>
                        <span class="emoji-icon anticon">📰</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/adRevenue"
                    >
                        <span class="emoji-icon anticon">💰</span>
                        <span> 광고 매출 </span>
                        <router-link to="/adRevenue" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/ad/layout"
                    >
                        <span class="emoji-icon anticon">😵</span>
                        <span>레이아웃</span>
                        <router-link to="/ad/layout" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/ad/slot"
                    >
                        <span class="emoji-icon anticon">😵</span>
                        <span>구좌</span>
                        <router-link to="/ad/slot" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/ad/advertise"
                    >
                        <span class="emoji-icon anticon">💡</span>
                        <span>광고 설정</span>
                        <router-link to="/ad/advertise" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/ad/benefit/section"
                    >
                        <span class="emoji-icon anticon">💡</span>
                        <span>혜택 탭 섹션</span>
                        <router-link to="/ad/benefit/section" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/ad/benefit/screen"
                    >
                        <span class="emoji-icon anticon">💡</span>
                        <span>혜택 탭 설정</span>
                        <router-link to="/ad/benefit/screen" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/searchAd"
                    title="검색 광고"
                >
                    <template #icon>
                        <span class="emoji-icon anticon"> 🔍</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/searchAd"
                    >
                        <span class="emoji-icon anticon">👬🏾</span>
                        <span>검색 광고 관리</span>
                        <router-link to="/searchAd" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/productSearchKeywordRank"
                    >
                        <span class="emoji-icon anticon">👬🏾</span>
                        <span>영양제 인기 검색어 관리</span>
                        <router-link to="/productSearchKeywordRank" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/event"
                    title="이벤트"
                >
                    <template #icon>
                        <span class="emoji-icon anticon">🎉</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/event"
                    >
                        <span class="emoji-icon anticon">🎉</span>
                        <span> 이벤트 </span>
                        <router-link to="/event" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/eventUser"
                    >
                        <span class="emoji-icon anticon">👥</span>
                        <span> 이벤트 참가자 </span>
                        <router-link to="/eventUser" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/eventUserAlarm"
                    >
                        <span class="emoji-icon anticon">🔔</span>
                        <span> 이벤트 사전알림 </span>
                        <router-link to="/eventUserAlarm" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/eventToast"
                    >
                        <span class="emoji-icon anticon">🍞</span>
                        <span> 이벤트 토스트 </span>
                        <router-link to="/eventToast" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/popup"
                    >
                        <span class="emoji-icon anticon">📢</span>
                        <span> 팝업 </span>
                        <router-link to="/popup" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/userSegment"
                    >
                        <span class="emoji-icon anticon">👬🏾</span>
                        <span> 사용자 세그먼트 </span>
                        <router-link to="/userSegment" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/crm-templates"
                    >
                        <span class="emoji-icon anticon">📨</span>
                        <span> CRM 템플릿 </span>
                        <router-link to="/crm-templates" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.RESEARCHER)"
                    key="/foodIntakeAdvisor"
                    title="식단"
                >
                    <template #icon>
                        <span class="emoji-icon anticon">🍽️</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/diet"
                    >
                        <span class="emoji-icon anticon">🍽️</span>
                        <span>식단 관리</span>
                        <router-link to="/diet" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/foodGuideDoc"
                    >
                        <span class="emoji-icon anticon">📖</span>
                        <span>식단 섭취 가이드</span>
                        <router-link to="/foodGuideDoc" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/foodIntakeAdvisor"
                    >
                        <span class="emoji-icon anticon">🚦</span>
                        <span>식단 신호등</span>
                        <router-link to="/foodIntakeAdvisor" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.RESEARCHER)"
                        key="/food"
                    >
                        <span class="emoji-icon anticon">🍖</span>
                        <span> 식품 </span>
                        <router-link to="/food" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.RESEARCHER)"
                        key="/foodManagementByPriority"
                    >
                        <span class="emoji-icon anticon">🍖</span>
                        <span>직접등록 식품</span>
                        <router-link to="/foodManagementByPriority" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/foodDictionary"
                    >
                        <span class="emoji-icon anticon">📖</span>
                        <span>식품 사전</span>
                        <router-link to="/foodDictionary" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/foodSynonym"
                    >
                        <span class="emoji-icon anticon">📖</span>
                        <span>식품 동의어</span>
                        <router-link to="/foodSynonym" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/andy-chance"
                    >
                        <span class="emoji-icon anticon">📖</span>
                        <span>앤디 찬스</span>
                        <router-link to="/andyChance" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/foodLensMap"
                    >
                        <span class="emoji-icon anticon">🔎</span>
                        <span>푸드렌즈 매칭</span>
                        <router-link to="/foodLensMap" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/intakeUnit"
                    >
                        <span class="emoji-icon anticon">🍴</span>
                        <span>음식 섭취단위</span>
                        <router-link to="/intakeUnit" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.RESEARCHER)"
                    key="/workout"
                    title="운동"
                >
                    <template #icon
                        ><span class="emoji-icon anticon">🏃</span></template
                    >
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/workout"
                    >
                        <span class="emoji-icon anticon">🏃</span>
                        <span>운동 관리</span>
                        <router-link to="/workout" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/workoutTrackerActivity"
                    >
                        <span class="emoji-icon anticon">🏃</span>
                        <span>헬스 트래커 운동 관리</span>
                        <router-link to="/workoutTrackerActivity" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/glucose"
                    title="혈당"
                >
                    <template #icon>
                        <span class="emoji-icon anticon">🩸</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/aiCoachingSchedule"
                    >
                        <span class="emoji-icon anticon">🤖</span>
                        <span> AI 코칭 스케쥴 </span>
                        <router-link to="/aiCoachingSchedule" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/librePractice"
                    >
                        <span class="emoji-icon anticon">🏥</span>
                        <span> 리브레 병원 관리 </span>
                        <router-link to="/librePractice" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/aiCoachingQATool"
                    >
                        <span class="emoji-icon anticon">🧰</span>
                        <span> CGM 기기 관리 / AI 코칭 QA </span>
                        <router-link to="/aiCoachingQATool" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.RESEARCHER)"
                    key="/reviews"
                    title="리뷰"
                >
                    <template #icon>
                        <span class="emoji-icon anticon">📝</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/review"
                    >
                        <span class="emoji-icon anticon">📝</span>
                        <span> 영양제 리뷰 관리 </span>
                        <router-link to="/review" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/product/reviews"
                    >
                        <span class="emoji-icon anticon">📝</span>
                        <span> 상품 리뷰 관리 </span>
                        <router-link to="/product/reviews" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.RESEARCHER)"
                    key="/point-shop"
                    title="포인트 샵"
                >
                    <template #icon>
                        <span class="emoji-icon anticon">🎁</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/point-shop/products/category"
                    >
                        <span class="emoji-icon anticon">📝</span>
                        <span> 카테고리 관리 </span>
                        <router-link to="/point-shop/products/category" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/point-shop/products"
                    >
                        <span class="emoji-icon anticon">📝</span>
                        <span> 상품 관리 </span>
                        <router-link to="/point-shop/products" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/point-shop/orders"
                    >
                        <span class="emoji-icon anticon">📝</span>
                        <span> 주문 관리 </span>
                        <router-link to="/point-shop/orders" />
                    </a-menu-item>
                </a-sub-menu>
                <a-menu-item
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/benefit"
                >
                    <span class="emoji-icon anticon">📰</span>
                    <span>혜택</span>
                    <router-link to="/benefit" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/column"
                >
                    <span class="emoji-icon anticon">📰</span>
                    <span>필라이즈 칼럼</span>
                    <router-link to="/column" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/press"
                >
                    <span class="emoji-icon anticon">📰</span>
                    <span>필라이즈 프레스</span>
                    <router-link to="/press" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.PHARMACIST)"
                    key="/question"
                >
                    <span class="emoji-icon anticon">📝</span>
                    <span>질문 관리</span>
                    <router-link to="/question" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/reviewTag"
                >
                    <span class="emoji-icon anticon">📝</span>
                    <span> 리뷰 태그 </span>
                    <router-link to="/reviewTag" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/user"
                >
                    <span class="emoji-icon anticon">👥</span>
                    <span> 사용자 </span>
                    <router-link to="/user" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/triggerBox"
                >
                    <span class="emoji-icon anticon">📦</span>
                    <span> 트리거 박스 </span>
                    <RouterLink to="/triggerBox" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/survey"
                >
                    <span class="emoji-icon anticon">☑️</span>
                    <span> 설문 관리 </span>
                    <RouterLink to="/survey" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/pillyzeImageAsset"
                >
                    <span class="emoji-icon anticon">️🖼️</span>
                    <span> 파일 관리 </span>
                    <RouterLink to="/pillyzeImageAsset" />
                </a-menu-item>
                <a-menu-item
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/adminNotification"
                >
                    <span class="emoji-icon anticon">📢</span>
                    <span>공지사항 및 이벤트 알림</span>
                    <router-link to="/adminNotification" />
                </a-menu-item>
                <a-sub-menu
                    v-if="authorities.includes(Role.ADMIN)"
                    key="/consumerGuideGroup"
                    title="연구소팁"
                >
                    <template #icon>
                        <span class="emoji-icon anticon">📖</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/consumerGuide"
                    >
                        <span class="emoji-icon anticon">📖</span>
                        <span>연구소 팁 분류</span>
                        <router-link to="/consumerGuide" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/consumerGuideDoc"
                    >
                        <span class="emoji-icon anticon">📖</span>
                        <span>연구소 팁</span>
                        <router-link to="/consumerGuideDoc" />
                    </a-menu-item>
                </a-sub-menu>
                <a-sub-menu
                    v-if="authorities.includes(Role.ADMIN)"
                    title="개발자 도구"
                >
                    <template #icon>
                        <span class="emoji-icon anticon">🛠️</span>
                    </template>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/notificationDevtool"
                    >
                        <span class="emoji-icon anticon">📢</span>
                        <span>알림 테스트</span>
                        <router-link to="/notificationDevtool" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/openAiChat"
                    >
                        <span class="emoji-icon anticon">🤖</span>
                        <span>ChatGPT 프롬프트 엔지니어링</span>
                        <router-link to="/openAiChat" />
                    </a-menu-item>
                    <a-menu-item
                        v-if="authorities.includes(Role.ADMIN)"
                        key="/qaTool"
                    >
                        <span class="emoji-icon anticon">🛠️</span>
                        <span>QA 도구</span>
                        <router-link to="/qaTool" />
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
        </a-layout-sider>
        <a-layout class="main-section">
            <a-layout-header
                style="
                    background: #fff;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                "
            >
                <menu-unfold-outlined
                    v-if="collapsed"
                    class="trigger"
                    @click="toggleCollapsed"
                />
                <menu-fold-outlined
                    v-else
                    class="trigger"
                    @click="toggleCollapsed"
                />
                {{ `${user.userName}, 안녕하세요 (API: ${apiServerAddress})` }}
                <UserProfile id="user-profile-dropdown-menu" />
            </a-layout-header>
            <a-alert
                v-if="user.loginType === 'AWS_COGNITO' && user.id == null"
                message="계정 연동이 필요합니다. 오른쪽 상단 사용자 프로필에서 정보 -> 사용 유저 아이디를 설정해주세요."
                type="warning"
                show-icon
                banner
            />
            <a-layout-content
                :style="{
                    margin: '24px 16px',
                    padding: '24px',
                    background: '#fff',
                    overflow: 'auto',
                    height: '100vh',
                }"
            >
                <router-view />
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<style lang="scss" scoped>
#side {
    background: #6c2bf2;
    text-align: center;
}

#side__menu {
    background: #6c2bf2;
    color: yellow;
    font-weight: bolder;
    text-align: left;
}

#app-layout-fold-trigger {
    width: 100%;
}

#app-layout-fold-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#app-layout-fold-trigger .trigger:hover {
    color: #1890ff;
}

#app-layout-fold-trigger .logo {
    height: 32px;
    background: #6c2bf2;
    margin-bottom: 32px;
}

#user-profile-dropdown-menu {
    margin-right: 10px;
}

.emoji-icon {
    font-size: 1.5rem !important;
}
</style>

<script lang="ts">
import UserProfile from '@/components/UserProfile.vue'
import { Role, useUserStore } from '@/store/user'
import {
    HomeOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons-vue'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'GlobalLayout',
    components: {
        UserProfile,
        HomeOutlined,
        MenuUnfoldOutlined,
        MenuFoldOutlined,
    },

    setup() {
        const collapsed = ref(false)
        const toggleCollapsed = () => {
            collapsed.value = !collapsed.value
        }
        const userStore = useUserStore()
        return { collapsed, toggleCollapsed, Role, user: userStore }
    },

    computed: {
        authorities(): Array<string> {
            return this.user.authorities || []
        },
        currentMenus(): Array<string> {
            return [this.$route.path]
        },
        apiServerAddress(): string {
            return process.env.VUE_APP_BASEURL
        },
    },
})
</script>
