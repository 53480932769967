<template>
    <FormModal
        ref="formRef"
        title="식품 수정"
        :resource-id="resourceId"
        uri="/api/food"
        :input-spec="updateSpec"
        :rules="updateRules"
    />
</template>

<script setup lang="ts">
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'
import FormModal from '@/components/modal/FormModal.vue'
import { onMounted, ref } from 'vue'
import store from '@/store'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stoplightFormChangeHook } from '@/views/food'
import { useEnumTypeStore } from '@/store/enumType'

const formRef = ref(null)

const props = defineProps({
    resourceId: {
        type: [Number, String],
        required: true,
    },
    onSearch: {
        type: Function,
    },
})

const updateSpec: FormSpecification = {
    name: {
        label: '식품명',
        value: null,
    },
    nameIndexed: {
        label: '인덱싱된 문자열',
        value: null,
        readonly: true,
    },
    brandName: {
        label: '브랜드명',
        value: null,
        component: InputComponents.AutoCompleteInput,
        uri: '/api/brand/name',
    },
    brandNameRaw: {
        label: '사용자입력 브랜드명',
        readonly: true,
        value: null,
    },
    searchTags: {
        label: '제품서치태그',
        value: null,
        component: InputComponents.ASelect,
        mode: SelectInputMode.TAGS,
    },
    pillyzeFoodType: {
        label: '식품 유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'pillyze-food-type',
    },
    foodSourceType: {
        label: '데이터 출처',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'food-source-type',
    },
    servingSizeNotFound: {
        label: '1회 제공량 없음 😞',
        value: false,
        component: InputComponents.BooleanInput,
        help: '1회 제공량이 없는 경우 단위는 1회분으로 통일됩니다.',
    },
    unitCode: {
        label: '제공량 단위',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'food-unit-code',
    },
    servingSize: {
        label: '1회 제공량',
        value: null,
        help: '1회 제공량이 없는 경우 "1회 제공량 없음" 체크박스를 체크해주세요.',
        component: InputComponents.AInputNumber,
        disabled: (form: any) => {
            return (
                form?.ingredients.length > 0 ||
                form?.servingSizeNotFound == 'true' ||
                form?.servingSizeNotFound == true
            )
        },
    },
    servingSizeChangePolicy: {
        label: '1회 제공량 수정 정책',
        value: null,
        help: '주의! 1회 제공량 수정시 영양성분은 직접 수정하지 마세요',
        component: InputComponents.EnumSelect,
        typeName: 'serving-size-change-policy',
    },
    nutrients: {
        label: '영양소',
        value: [],
        help: '(1회 제공량 기준)',
        component: InputComponents.FoodNutrientInput,
        disabled: (form: any) => form?.ingredients.length > 0,
    },
    intakeUnits: {
        label: '섭취단위',
        component: InputComponents.FoodIntakeUnitInput,
    },
    ingredients: {
        label: '재료',
        value: [],
        component: InputComponents.FoodIngredientInput,
        help: '식품의 재료를 입력합니다., 재료를 구성하면 재료에 의해 식품 영양소가 입력됩니다.',
        input: async (value: any) => {
            const response = await adminApi<ApiResponse<Record<any, any>>>(
                `/api/food/ingredient-summary/${props.resourceId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(value),
                }
            )
            //@ts-ignore
            const formSpec = formRef.value.form.formSpec
            const result = response.result
            formSpec.servingSize.value = result?.gramAmount
            formSpec.calorie.value = result?.calorie
            formSpec.nutrients.value = result?.ingredientSummaryAggregate.map(
                (it: any) => ({
                    nutrientName: it.nutrientName,
                    amount: it.nutrientGramAmount,
                    unit: it.unit,
                })
            )
        },
    },
    calculatedNutrients: {
        label: '계산된 영양소',
        value: null,
        readonly: true,
    },
    sumOfNutrientAmount: {
        label: '영양소의 총량',
        value: null,
        readonly: true,
        help: '1회 제공량 보다 영양소 총량이 많다면 데이터에 문제가 있다.',
    },
    totalAmount: {
        label: '총 제공량',
        value: null,
        component: InputComponents.AInputNumber,
    },
    calorie: {
        label: '열량 (kcal)',
        value: null,
        component: InputComponents.AInputNumber,
        disabled: (form: any) => form?.ingredients.length > 0,
    },
    year: {
        label: '출시년도',
        value: null,
        component: InputComponents.AInputNumber,
        help: '미입력시 현재 연도를 자동 입력',
    },
    status: {
        label: '상태',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'food-status',
    },
    adminMemo: {
        label: '관리자 메모',
        value: null,
        type: InputType.TEXTAREA,
        help: '',
    },
    dataQuality: {
        label: '데이터 품질수준',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'data-quality-code',
    },
    isRepresentative: {
        label: '대표음식',
        value: null,
        help: '김치, 김밥 등 일반적으로 사용되는 식품군의 대표',
        component: InputComponents.BooleanInput,
        trueText: '대표음식',
        falseText: '일반음식',
    },
    productInfoImageUrl: {
        label: '제품 이미지 주소',
        readonly: true,
        help: '고객이 입력한 주소',
        value: null,
    },
    nutritionInfoImageUrl: {
        label: '영양성분 이미지 주소',
        readonly: true,
        help: '고객이 입력한 주소',
        value: null,
    },
    category: {
        label: '카테고리',
        value: null,
        component: InputComponents.AutoCompleteInput,
        uri: '/api/food/category-name',
        select: (value) => {
            //@ts-ignore
            const formSpec = formRef?.value.form.formSpec
            formSpec.categoryBias.value = value?.bias
        },
    },
    categoryBias: {
        label: '카테고리 Bias',
        value: null,
        readonly: true,
    },
    searchWeight: {
        label: '검색 가중치',
        value: null,
        component: InputComponents.AInputNumber,
        help: '0 ~ 11 까지 입력 가능합니다. (10은 대표음식과 같은 수준, 11은 대표음식 보다 조금 높은 수준)',
    },
    inMenu: {
        label: '메뉴에 포함',
        value: '포함',
        component: InputComponents.BooleanInput,
        help: '드링크 메뉴에 포함 여부',
        trueText: '포함',
        falseText: '미포함',
    },
    customCoupangQuery: {
        label: '쿠팡 파트너스 검색 쿼리',
        value: null,
        help: '기본적인 조합 (제목 + 브랜드)로 검색이 되지 않을 경우 사용 (만약 항상 비노출을 원한다면 "Gold box" 를 입력)',
    },
    buyNowInCoupangAllowed: {
        label: '쿠팡 파트너스 바로구매 허용',
        value: null,
        component: InputComponents.BooleanInput,
        trueText: '노출',
        falseText: '비노출',
    },
    consumerGuide: {
        label: '연구소 팁',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'consumer-guide',
    },
    stoplight: {
        label: '신호등',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'food-stoplight',
        formChangeHook: (form: Record<any, any>) => {
            //@ts-ignore
            const formSpec = formRef.value.form.formSpec
            stoplightFormChangeHook(form, formSpec)
        },
        help: '값을 갱신하기 위해서는 기존 값을 제거해야합니다.(- 클릭)',
    },
    stoplightReason: {
        label: '신호등 이유',
        value: null,
        readonly: true,
    },
    recipeLink: {
        label: '레시피 출처',
        value: null,
    },
    recipeLinkType: {
        label: '레시피 유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'recipe-type',
    },
}

const updateRules = {
    name: [required],
    pillyzeFoodType: [required],
    unitCode: [required],
    calorie: [required],
}

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums([
        'pillyze-food-type',
        'food-source-type',
        'food-unit-code',
        'data-quality-code',
        'food-status',
        'serving-size-change-policy',
        'food-stoplight',
        'contact-link-type',
    ])
})
</script>
