<template>
    <div style="background-color: #f9fefe; padding: 13px">
        <a-row>
            <a-form ref="formRule" :model="form" :rules="rules" layout="inline">
                <a-form-item label="role">
                    <a-select
                        v-model:value="form.role"
                        :options="roleOptions"
                    />
                </a-form-item>
                <a-form-item name="content" label="내용">
                    <a-textarea
                        v-model:value="form.content"
                        style="width: 600px !important"
                        @change="(event: any) => onChangeMessage(event, form)"
                    />
                    <a-row style="justify-content: end">
                        <a-typography-text>
                            {{ `token 수: ${form.tokenLength}` }}
                        </a-typography-text>
                    </a-row>
                    <a-row>
                        <a-typography-text>
                            토큰의 최대수는 모델별로 3072 ~ 8192개 입니다.
                        </a-typography-text>
                    </a-row>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="add">
                        메세지 추가
                    </a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-row style="margin-top: 25px">
            <draggable :list="valueRef" item-key="foodId">
                <template #item="{ element, index }">
                    <a-form
                        :model="element"
                        :rules="rules"
                        layout="inline"
                        style="border-color: #6c2bf2; margin-bottom: 25px"
                    >
                        <a-form-item>
                            <swap-outlined :rotate="90" />
                        </a-form-item>
                        <a-form-item label="role">
                            <a-select
                                v-model:value="element.role"
                                :options="roleOptions"
                            />
                        </a-form-item>
                        <a-form-item name="content" label="내용">
                            <a-textarea
                                v-model:value="element.content"
                                style="width: 600px !important"
                                @change="(event: any) => onChangeMessage(event, element)"
                            />
                            <a-row
                                v-if="element.tokenLength"
                                style="justify-content: end"
                            >
                                <a-typography-text>
                                    {{ `token 수: ${element.tokenLength}` }}
                                </a-typography-text>
                            </a-row>
                        </a-form-item>
                        <a-form-item>
                            <a-button type="danger" @click="() => pop(index)">
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                    </a-form>
                </template>
            </draggable>
        </a-row>
        <a-space direction="vertical">
            <a-typography-text>
                role에 따라 응답을 설정합니다.
            </a-typography-text>
            <a-typography-text>
                '시스템': 시스템 메시지는 대화의 시작 부분에 사용되며, 대화의
                컨텍스트와 기대되는 동작을 설정하는 데 도움이 됩니다. 시스템
                메시지는 일반적으로 ChatGPT에게 특정 주제에 대한 정보를
                제공하거나, 어떤 방식으로 대화를 진행할지 가이드합니다.
            </a-typography-text>
            <a-typography-text code
                >시스템: "넌 친절하고 상냥해"</a-typography-text
            >
            <a-typography-text>
                '지원자': 도우미 메시지는 이전에 생성된 ChatGPT의 응답을
                포함합니다. 이전 응답을 포함함으로써, ChatGPT는 이전 대화의
                문맥을 더 잘 이해하고 적절한 후속 응답을 생성할 수 있습니다.
            </a-typography-text>
            <a-typography-text code>
                지원자: "비타민A는 아침에 먹는게 좋아요."
            </a-typography-text>
            <a-typography-text>
                '사용자': 사용자 메시지는 실제 사용자의 질문이나 명령을
                포함합니다. ChatGPT는 이러한 사용자 메시지를 바탕으로 적절한
                응답을 생성하려고 시도합니다.
            </a-typography-text>
            <a-typography-text code
                >사용자: "비타민A는 언제먹는게 좋니."
            </a-typography-text>
        </a-space>
    </div>
</template>

<script setup lang="ts">
import { ref, toRef } from 'vue'
import { required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { SwapOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { mlApi } from '@/fetchTemplate'
import { debounce } from 'lodash'

type Message = {
    role: string
    content: string
    tokenLength?: number
}

const roleOptions = [
    { label: '사용자', value: 'user' },
    { label: '지원자', value: 'assistant' },
    { label: '시스템', value: 'system' },
]

const props = defineProps({
    rules: {
        type: Object,
        default: () => ({
            role: [required],
            content: [required],
        }),
    },
    value: {
        type: Array,
        default: () => [],
    },
})

const form = ref<Message>({
    role: '',
    content: '',
    tokenLength: 0,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: Message) => {
    valueRef.value.push(value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
}
const add = () => {
    return formRule.value.validate().then(() => {
        if (props.value != null) {
            push(form.value)
            form.value = {
                role: '',
                content: '',
            }
        }
    })
}

const onChangeMessage = debounce(async (event: any, form: Record<any, any>) => {
    if (event.target.value) {
        const response = await mlApi(`/open-ai/token-length`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: event.target.value }),
        })
        form.tokenLength = response
    }
}, 500)
</script>
