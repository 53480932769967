<template>
    <a-mentions
        class="template__input"
        :value="value"
        @change="change"
        prefix="$"
        style="height: auto"
    >
        <a-mentions-option
            v-for="opt in options"
            :key="opt.value"
            :value="opt.label"
        >
            {{ opt.label.replace('{', '').replace('}', '') }}
        </a-mentions-option>
    </a-mentions>
</template>

<script setup lang="ts">
import { useEnumTypeStore } from '@/store/enumType'
import { ref, onMounted } from 'vue'

const props = defineProps({
    value: {
        type: String,
    },
    typeName: {
        type: String,
        default: 'default-format-args',
    },
})

const emits = defineEmits(['update:value'])

const change = (value: any) => {
    emits('update:value', value)
}

const options = ref<Array<any>>([])

const fetchOptions = async () => {
    const enumTypeStore = useEnumTypeStore()
    options.value = enumTypeStore.getEnumType(props.typeName).map((it: any) => {
        return { value: it.value, label: `{${it.label}}` }
    })
}

onMounted(async () => {
    fetchOptions()
})
</script>
