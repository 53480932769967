import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_BlindPopconfirm = _resolveComponent("BlindPopconfirm")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "댓글",
    uri: "/api/v1/reviews",
    "filter-spec": _ctx.filterSpec,
    "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
    scroll: { x: 2000, y: 650 },
    columns: _ctx.columns,
    exportable: "",
    "load-on-mount": ""
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_a_button, {
        style: {"color":"black"},
        loading: _ctx.loading,
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode("리뷰 상태 변경")
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('NORMAL', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("노멀")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('ADMIN_BLIND', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("블라인드")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('ADMIN_DELETED', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("운영자 삭제")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        style: {"color":"black"},
        loading: _ctx.loading,
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode("베스트 지정")
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateBest('true', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("Best")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateBest('false', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("Unbest")
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    detail: _withCtx(({ record, search, fetchResource }) => [
      _createVNode(_component_a_descriptions, {
        title: "리뷰 상세",
        bordered: "true",
        layout: "vertical"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions_item, { label: "리뷰 ID" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.id), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "사용자 ID" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.userId), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "제품 ID" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.productId), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "상태" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.status), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "베스트 여부" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.isBest), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "장점" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.goodText), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "단점" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.badText), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "이미지" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_image_preview_group, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.reviewImage, (image, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: index,
                      style: {"display":"inline-grid"}
                    }, [
                      _createVNode(_component_a_image, {
                        width: 200,
                        src: image.url
                      }, null, 8, ["src"]),
                      _createVNode(_component_BlindPopconfirm, {
                        title: `이미지를 ${
                                    (image.blinded && '노출') || '블라인드'
                                } 하시겠습니까?`,
                        uri: `/api/v1/reviews/blind/image/${image.id}`,
                        blinded: image.blinded,
                        onAfterBlind: 
                                    () => {
                                        search()
                                        fetchResource()
                                    }
                                
                      }, null, 8, ["title", "uri", "blinded", "onAfterBlind"])
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["filter-spec", "row-selection", "columns"]))
}