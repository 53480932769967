<template>
    <span style="display: inline-block; white-space: nowrap; width: 90%">
        <a-select
            :value="valueByMode"
            :options="options"
            :mode="mode"
            :disabled="disabled"
            @change="(value: any) => $emit('update:value', value)"
            show-search
        />
    </span>
</template>

<script lang="ts">
import { Companion, useCompanionStore } from '@/store/companion'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'UserInput',

    props: {
        value: {
            type: [String, Array],
            default: '',
        },
        mode: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        options() {
            const companionStore = useCompanionStore()
            const companion = companionStore.companion
            console.log(companion)
            const userNameList = companion.map((it: Companion) => it.userName)

            return companion
                ?.filter(
                    (it: Companion, index: number) =>
                        it.userName != null &&
                        userNameList.indexOf(it.userName) === index
                )
                .map((it: Companion) => ({
                    label: it.userName,
                    value: it.userName,
                }))
        },

        companion() {
            return useCompanionStore().companion
        },

        valueByMode() {
            if (this.mode === 'tags') {
                return this.value || []
            }
            return this.value
        },
    },

    emits: ['update:value'],
})
</script>
