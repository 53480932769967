import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!

  return (_openBlock(), _createBlock(_component_a_popconfirm, {
    title: _ctx.title,
    "ok-text": "실행",
    "cancel-text": "취소",
    onConfirm: _ctx.confirm,
    onCancel: _ctx.cancel,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_button, { type: "primary" }, {
        default: _withCtx(() => [
          _createTextVNode("수정")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "onConfirm", "onCancel", "disabled"]))
}