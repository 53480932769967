import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_modal_button = _resolveComponent("modal-button")!

  return (_openBlock(), _createBlock(_component_modal_button, _mergeProps(_ctx.$props, {
    ref: "modalButtonRef",
    onSubmit: _ctx.patch,
    onAfterShow: _ctx.afterShow
  }), {
    icon: _withCtx(({ showModal }) => [
      _createVNode(_component_edit_outlined, { onClick: showModal }, null, 8, ["onClick"])
    ]),
    button: _withCtx(() => [
      _createTextVNode(" 수정 ")
    ]),
    header: _withCtx((data) => [
      _renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps(data)))
    ]),
    footer: _withCtx((data) => [
      _renderSlot(_ctx.$slots, "footer", _normalizeProps(_guardReactiveProps(data)))
    ]),
    _: 3
  }, 16, ["onSubmit", "onAfterShow"]))
}