import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
//import 'ant-design-vue/dist/antd.css'
import '@/assets/global.scss'

import 'highlight.js/styles/ocean.css'

import Antd from 'ant-design-vue'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const app = createApp(App)

//app.config.productionTip = false

app.use(Antd).use(store).use(router)
//@ts-ignore
app.use(Vue3Lottie)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.mount('#app')
