<template>
    <PForm
        :title="title"
        :loading="loading"
        :rules="rules"
        :input-spec="formSpec"
        @submit="patch"
    >
        <template #button> 수정 </template>
    </PForm>
</template>

<script setup lang="ts">
import PForm from '@/components/PForm.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { message } from 'ant-design-vue'
import { cloneDeep } from 'lodash'
import { onMounted, onUnmounted, ref, toRef, watch } from 'vue'
import { FormSpecification } from './InputMapper.vue'
import useFormState from './useFormState'

const {
    formSpec,
    setFormValue,
    setFormValues,
    overwriteFormSpec,
    resetForm,
    setInputSpec,
} = useFormState()

const emits = defineEmits(['listenSpec'])

watch(
    () => cloneDeep(formSpec.value),
    (currentSpec: FormSpecification) => {
        emits('listenSpec', currentSpec, setInputSpec)
    },
    { deep: true }
)

const props = defineProps({
    title: {
        type: String,
    },
    uri: {
        type: String,
    },
    resourceId: {
        type: [Number, String],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: Object,
        default: () => ({}),
    },
    inputSpec: {
        type: Object,
        default: () => ({}),
    },
})

const loadingProp = toRef(props, 'loading')

const patch = async (form: Record<any, any>) => {
    loadingProp.value = true

    try {
        const response = await adminApi<ApiResponse<void>>(
            `${props.uri}/${props.resourceId}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form),
            }
        )

        if (response.success === undefined || response.success === false) {
            message.error(
                response.errorMessage || '예외 발생 - 개발자에게 문의하세요'
            )
        } else {
            fetchResource()
            loadingProp.value = false
        }
    } catch (error) {
        const err = error as Error
        loadingProp.value = false
        message.error(err.message)
        throw error
    }
}

const fetchResource = async (id: number | null) => {
    loadingProp.value = true
    const response = await adminApi<ApiResponse<Record<string, any>>>(
        `${props.uri}/${id || props.resourceId}`
    )
    const resource = response.result

    if (resource != null) {
        setFormValues(resource)
    }
    loadingProp.value = false
}

onMounted(() => {
    overwriteFormSpec(props.inputSpec)
    fetchResource().then()
})

onUnmounted(() => {
    resetForm()
})

defineExpose({
    setFormValue,
    formSpec,
    fetchResource,
})
</script>
