<template>
    <ResourceTable
        resource-name="events"
        title="필라이즈 이벤트"
        uri="/api/v1/events"
        :columns="columns"
        :create-spec="inputSpec"
        :update-spec="inputSpec"
        :filter-spec="filterSpec"
        :update-content-spec="contentInputSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1400, y: 1350 }"
        :pagination="{ pageSize: 50 }"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        load-on-mount
    >
        <template #actions="{ uri, search }">
            <a-button
                @click="copyEvent(uri, search)"
                :disabled="selectedRowKeys.length !== 1"
            >
                복사
            </a-button>
        </template>
    </ResourceTable>
</template>

<script setup lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { Column } from '.'
import { formatLocalDateTime } from '@/util/formmater'
import { ref, onMounted, Ref } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'
import globalFormState from '@/components/globalFormState'
import { message } from 'ant-design-vue'
import { adminApi } from '@/fetchTemplate'
const { formSpec } = globalFormState
const columns: Array<Column> = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 50,
        fixed: 'left',
    },
    {
        title: '이벤트명',
        dataIndex: 'name',
        width: 100,
    },
    {
        title: '시작일',
        dataIndex: 'startAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
    {
        title: '종료일',
        dataIndex: 'endAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
    {
        title: '담당자',
        dataIndex: 'manager',
        width: 50,
    },
    {
        title: 'APP 노출',
        dataIndex: 'visible',
        customRender: (value: any) => {
            if (value.value) {
                return 'O'
            }
            return 'X'
        },
        width: 50,
    },
    {
        title: 'WEB 노출',
        dataIndex: 'webVisible',
        customRender: (value: any) => {
            if (value.value) {
                return 'O'
            }
            return 'X'
        },
        width: 50,
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 100,
    },
]

const contentInputSpec = ref<FormSpecification>({
    contentAggregation: {
        label: '컨텐츠 리스트',
        component: InputComponents.EventContentInput,
        value: {
            contentList: [],
            proofContentList: [],
        },
    },
})

const filterSpec = ref<FormSpecification>({
    name: {
        label: '이벤트명',
        value: null,
    },
})

const inputSpec = ref<FormSpecification>({
    name: { label: '이벤트명', value: null },
    eventType: {
        label: '타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'event-type',
        select: (event: any) => {
            if (event === 'PAYBACK') {
                formSpec.value.paybackBuyStartAt.hidden = false
                formSpec.value.paybackBuyEndAt.hidden = false
                formSpec.value.paybackAt.hidden = false
                formSpec.value.paybackProductName.hidden = false
                formSpec.value.paybackAmount.hidden = false
                formSpec.value.fee.hidden = false
            } else {
                formSpec.value.paybackBuyStartAt.hidden = true
                formSpec.value.paybackBuyEndAt.hidden = true
                formSpec.value.paybackAt.hidden = true
                formSpec.value.paybackProductName.hidden = true
                formSpec.value.paybackAmount.hidden = true
                formSpec.value.fee.hidden = true
            }
        },
    },
    promoteType: {
        label: '선발 기준',
        value: 'ETC',
        component: InputComponents.EnumSelect,
        typeName: 'event-promote-type',
    },
    recruitType: {
        label: '모집 타입',
        value: 'ETC',
        component: InputComponents.EnumSelect,
        typeName: 'event-recruit-type',
    },
    startAt: {
        label: '시작일',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    recruitAt: {
        label: '모집마감일',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    announceAt: {
        label: '추첨발표일',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    endAt: {
        label: '종료일',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    paybackBuyStartAt: {
        label: '페이백 구매 시작일',
        value: null,
        component: InputComponents.ADatetimePicker,
        hidden: (record?: Record<string, any>) =>
            record?.eventType !== 'PAYBACK',
    },
    paybackBuyEndAt: {
        label: '페이백 구매 종료일',
        value: null,
        component: InputComponents.ADatetimePicker,
        hidden: (record?: Record<string, any>) =>
            record?.eventType !== 'PAYBACK',
    },
    paybackAt: {
        label: '페이백 지급일',
        value: null,
        component: InputComponents.ADatetimePicker,
        hidden: (record?: Record<string, any>) =>
            record?.eventType !== 'PAYBACK',
    },
    paybackProductName: { label: '페이백 상품명', value: null },
    paybackAmount: {
        label: '페이백 금액',
        value: null,
        component: InputComponents.AInputNumber,
        hidden: (record?: Record<string, any>) =>
            record?.eventType !== 'PAYBACK',
    },
    fee: {
        label: '참가비',
        value: null,
        component: InputComponents.AInputNumber,
    },
    promotedReviewStartAt: {
        label: '당첨자 리뷰 작성 시간 (시작)',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    promotedReviewEndAt: {
        label: '당첨자 리뷰 작성 시간 (종료)',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    title: { label: '메타 타이틀', value: null },
    webviewTitle: { label: '웹뷰 타이틀', value: null },
    phoneCertTitle: { label: '휴대폰 인증 타이틀', value: null },
    phoneCertBtnText: { label: '휴대폰 인증 버튼 텍스트', value: null },
    description: { label: '메타 디스크립션', value: null },
    maxUserCount: { label: '최종 모집인원', value: null },
    eventUrl: { label: '커스텀 이벤트페이지 URL', value: null },
    bannerLayoutDesc: {
        label: '앱 배너 이미지',
        value: null,
        type: InputType.TEXTAREA,
    },
    bannerLayoutDesc2: {
        label: '앱 배너 이미지(2)',
        value: null,
        help: '(포미테스터) 모집마감 배너',
        type: InputType.TEXTAREA,
    },
    bannerLayoutDesc3: {
        label: '앱 배너 이미지(3)',
        value: null,
        help: '(포미테스터) 결과확인 배너',
        type: InputType.TEXTAREA,
    },
    joinPageBannerImageUrl: {
        label: '상단 고정 배너',
        value: null,
        component: InputComponents.ContentImageInput,
        path: '/banner',
    },
    shareImageUrl: {
        label: '공유하기 이미지(og:image)',
        value: null,
        component: InputComponents.ContentImageInput,
        path: '/banner',
    },
    webHomeTopBannerImageUrl: {
        label: '(WEB PC) 홈 상단 배너 이미지',
        value: null,
        component: InputComponents.ContentImageInput,
        path: '/banner',
    },
    webHomeTopBannerImageUrl2: {
        label: '(WEB MO) 홈 상단 배너 이미지',
        value: null,
        component: InputComponents.ContentImageInput,
        path: '/banner',
    },
    productId: {
        label: '관련 영양제 아이디',
        value: null,
        component: InputComponents.AInputNumber,
    },
    shoppingProductId: {
        label: '관련 쇼핑상품아이디',
        value: null,
        component: InputComponents.AInputNumber,
    },
    visible: {
        label: 'APP 노출',
        value: null,
        component: InputComponents.BooleanInput,
        trueText: '노출',
        falseText: '비노출',
    },
    webVisible: {
        label: 'WEB 노출',
        value: null,
        component: InputComponents.BooleanInput,
        trueText: '노출',
        falseText: '비노출',
    },
    showRemainingTime: {
        label: '남은시간 노출',
        value: null,
        component: InputComponents.BooleanInput,
        trueText: '노출',
        falseText: '비노출',
    },
    externalLink: {
        label: '웹 노출 참가버튼 링크',
        value: null,
    },
    privacyLink: {
        label: '개인정보 제3자 제공동의 이미지URL',
        component: InputComponents.ContentImageInput,
        value: null,
        path: '/privacy',
    },
    feePolicyLink: {
        label: '참가비 결제 약관 이미지URL',
        component: InputComponents.ContentImageInput,
        value: null,
        path: '/privacy',
    },
    userInfoCollectionTypeList: {
        label: '유저정보 수집타입',
        value: [],
        component: InputComponents.EnumSelect,
        typeName: 'event-user-info-collection-type',
        mode: SelectInputMode.MULTIPLE,
    },
    topButtonImageUrl: {
        label: '위로 가기 버튼 이미지',
        value: null,
        component: InputComponents.ContentImageInput,
        path: '/event',
    },
    pillyzeColumnId: {
        label: '칼럼 ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
})

const rules = {
    name: [required],
    title: [required],
    description: [required],
    shareImageUrl: [required],
    eventType: [required],
    eventPromoteType: [required],
    visible: [required],
    webVisible: [required],
    startAt: [required],
    endAt: [required],
    webviewTitle: [required],
    privacyLink: [required],
    recruitType: [required],
}

const selectedRowKeys: Ref<Array<number>> = ref([])

const rowSelection = ref({
    selectedRowKeys: [],
    selectedRows: [],
    onChange: (selectedRowKeys: any, selectedRows: any) => {
        rowSelection.value.selectedRowKeys = selectedRowKeys
        rowSelection.value.selectedRows = selectedRows
    },
})

function onSelectChange(keys: Array<number>) {
    selectedRowKeys.value = keys
}

async function copyEvent(uri: string, search: () => void) {
    const id =
        selectedRowKeys.value.length === 1 ? selectedRowKeys.value[0] : null

    if (id == null) {
        return message.error('하나의 팝업을 선택해주세요')
    }

    await adminApi(`${uri}/copy/${id}`, {
        method: 'POST',
    })
    await search()
}

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums(['event-type'])
    enumTypeStore.dispatchEnums(['event-promote-type'])
    enumTypeStore.dispatchEnums(['event-recruit-type'])
    enumTypeStore.dispatchEnums(['event-user-info-collection-type'])
})
</script>
