<template>
    <UpdateFormModal
        :title="title"
        :resource-id="resourceId"
        uri="/api/food-user-request"
        :input-spec="updateSpec"
        :rules="updateRules"
        @after-submit="onSearch"
    />
</template>

<script setup lang="ts">
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'

defineProps({
    title: {
        type: String,
        required: true,
    },
    resourceId: {
        type: [Number, String],
        required: true,
    },
    onSearch: {
        type: Function,
        required: true,
    },
})

const updateRules = {
    foodName: [required],
}

const updateSpec: FormSpecification = {
    userId: {
        label: '사용자 ID',
        value: null,
        readonly: true,
    },
    foodId: {
        label: '식품 ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    type: {
        label: '요청 유형',
        value: null,
    },
    foodName: {
        label: '식품명',
        value: null,
    },
    brandName: {
        label: '브랜드명',
        value: null,
    },
    foodType: {
        label: '식품 유형',
        value: null,
    },
    category1: {
        label: '식품 카테고리1',
        value: null,
    },
    category2: {
        label: '식품 카테고리2',
        value: null,
    },
    year: {
        label: '출시년도',
        value: null,
        component: InputComponents.AInputNumber,
    },
    servingSize: {
        label: '1회 제공량',
        value: null,
        component: InputComponents.AInputNumber,
    },
    totalAmount: {
        label: '총 제공량',
        value: null,
        component: InputComponents.AInputNumber,
    },
    calorie: {
        label: '열량',
        value: null,
        component: InputComponents.AInputNumber,
    },
    nutrients: {
        label: '영양소',
        value: [],
        component: InputComponents.ProductNutrientInput,
    },
}
</script>
