<template>
    <div style="background-color: #f9fefe; padding: 13px">
        <a-row>
            <a-form ref="formRule" :model="form" :rules="rules" layout="inline">
                <a-form-item label="식품명">
                    <ingredient-input
                        v-model:value="form.foodName"
                        name="foodName"
                        @select="(event) => onSelectFood(form, event)"
                        width="280px"
                        mode="default"
                    />
                </a-form-item>
                <a-form-item name="amount" label="양">
                    <korean-number-input
                        v-model:value="form.amount"
                        has-addon-after-slot
                        width="180px"
                    >
                        <template #addonAfter>
                            <a-select
                                v-model:value="form.unit"
                                :options="form.unitOptions"
                            />
                        </template>
                    </korean-number-input>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="add"> 재료 추가 </a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-row>
            <draggable :list="valueRef" item-key="foodId">
                <template #item="{ element, index }">
                    <a-form
                        :model="element"
                        :rules="rules"
                        layout="inline"
                        style="border-color: #6c2bf2"
                    >
                        <a-form-item>
                            <swap-outlined :rotate="90" />
                        </a-form-item>
                        <a-form-item label="식품명">
                            <ingredient-input
                                v-model:value="element.foodName"
                                label="식품명"
                                :name="`foodName${index}`"
                                @select="
                                    (event) => onSelectFood(element, event)
                                "
                                width="280px"
                                mode="default"
                            />
                        </a-form-item>
                        <a-form-item :name="`amount${index}`" label="양">
                            <korean-number-input
                                v-model:value="element.amount"
                                @input="() => emits('change', valueRef)"
                                has-addon-after-slot
                                width="180px"
                            >
                                <template #addonAfter>
                                    <a-select
                                        v-model:value="element.unit"
                                        :options="element.unitOptions"
                                    />
                                </template>
                            </korean-number-input>
                        </a-form-item>
                        <a-form-item>
                            <a-button type="danger" @click="() => pop(index)">
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                    </a-form>
                </template>
            </draggable>
        </a-row>
    </div>
</template>

<script setup lang="ts">
import { ref, toRef } from 'vue'
import IngredientInput from '@/components/input/IngredientInput.vue'
import KoreanNumberInput from '@/components/input/KoreanNumberInput.vue'
import { aboveOrEqualToZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { SwapOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { SelectOption } from '@/store/enumType'

type Amounts = {
    id?: number
    foodName?: string
    foodId?: number
    amount: number
    unit?: string
    unitOptions?: Array<SelectOption>
}

const props = defineProps({
    rules: {
        type: Object,
        default: () => ({
            foodName: [required],
            amount: [required, aboveOrEqualToZero],
            unit: [required],
        }),
    },
    value: {
        type: Array,
        default: () => [],
    },
})

const emits = defineEmits(['change'])

const form = ref<Amounts>({
    amount: 0,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: Amounts) => {
    valueRef.value.push(value)
    emits('change', valueRef.value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
    emits('change', valueRef.value)
}

const add = () => {
    return formRule.value.validate().then(() => {
        if (form.value.unit == undefined) {
            alert('섭취 단위는 필수값입니다.')
            return
        }
        if (props.value != null) {
            push(form.value)
            form.value = {
                amount: 0,
            }
        }
    })
}

const onSelectFood = async (foodAmount: any, event: any) => {
    foodAmount.foodName = event.label
    foodAmount.foodId = event.value

    const response = await adminApi<ApiResponse<Array<SelectOption>>>(
        `/api/food/food-intake-unit/${event.value}`
    )
    form.value.unitOptions = (response.result?.length == 0 && [
        { label: 'g', value: 'g' },
    ]) || [
        ...(response.result as Array<SelectOption>),
        { label: 'g', value: 'g' },
    ]
}
</script>
