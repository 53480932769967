<template>
    <span>
        <AutoCompleteInput
            :label="label"
            uri="/api/products/product-name"
            :width="width"
            :value="value"
            @update:value="change"
            @select="select"
            :mode="mode"
            :disabled="disabled"
        />
        <a-button
            v-if="mode == 'default'"
            :disabled="value == null"
            @click="showProduct(value)"
            >제품 보기</a-button
        >
    </span>
</template>

<script setup lang="ts">
import AutoCompleteInput from '@/components/input/AutoCompleteInput.vue'
import router from '@/router'

defineProps({
    value: {
        type: Number,
    },
    label: {
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: '20em',
    },
    mode: {
        type: String,
        default: 'default',
    },
})

const emit = defineEmits(['update:value', 'select'])

const showProduct = (id: number | undefined) => {
    router.push({
        path: `/userFeedback/products/edit/${id}?visible=true`,
    })
}

const change = (value: any) => {
    emit('update:value', value)
}

const select = (value: any) => {
    emit('select', value)
}
</script>
