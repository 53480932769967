import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"display":"flex","align-items":"flex-end"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_UpdatePopConfirm = _resolveComponent("UpdatePopConfirm")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_BlindPopconfirm = _resolveComponent("BlindPopconfirm")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "상품 댓글",
    uri: "/api/v1/product/reviews",
    "filter-spec": _ctx.filterSpec,
    "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
    scroll: { x: 2000, y: 650 },
    columns: _ctx.columns,
    "load-on-mount": "",
    exportable: ""
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_a_button, {
        style: {"color":"black"},
        loading: _ctx.loading,
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode("리뷰 상태 변경")
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('NORMAL', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("노멀")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('ADMIN_BLIND', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("블라인드")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus('ADMIN_DELETED', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("운영자 삭제")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        style: {"color":"black"},
        loading: _ctx.loading,
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode("베스트 지정")
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateBest('true', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("Best")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateBest('false', uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode("Unbest")
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    detail: _withCtx(({ record, search, fetchResource }) => [
      _createVNode(_component_a_descriptions, {
        title: "리뷰 상세",
        bordered: "true",
        layout: "vertical"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions_item, { label: "리뷰 ID" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.id), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "리뷰 점수" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.score), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "사용자 ID" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.userId), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "베스트 여부" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.isBest), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, {
            label: "베스트 점수",
            span: 2
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_a_input_number, _mergeProps({ style: {"margin-right":"6px"} }, _ctx.rankScore, {
                  value: record.rankScore,
                  "onUpdate:value": ($event: any) => ((record.rankScore) = $event),
                  onChange: _ctx.handleRankScore
                }), null, 16, ["value", "onUpdate:value", "onChange"]),
                _createVNode(_component_UpdatePopConfirm, {
                  title: "수정하시겠습니까?",
                  uri: `/api/v1/product/reviews/${record.id}/rank`,
                  payload: _ctx.rankScorePayload,
                  onAfterBlind: 
                                () => {
                                    search()
                                    fetchResource()
                                }
                            
                }, null, 8, ["uri", "payload", "onAfterBlind"])
              ])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, {
            label: "댓글",
            span: 3
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.content), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, {
            label: "이미지",
            span: 3
          }, {
            default: _withCtx(() => [
              (record?.imageList?.length === 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, " 이미지 없음 "))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_image_preview_group, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.imageList, (image, index) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: index,
                        style: {"display":"inline-grid","margin-right":"6px"}
                      }, [
                        _createVNode(_component_a_image, {
                          style: {"margin-bottom":"6px"},
                          width: 128,
                          src: image.thumbnailImageUrl
                        }, null, 8, ["src"]),
                        _createVNode(_component_BlindPopconfirm, {
                          title: `이미지를 ${
                                        (image.blinded && '노출') || '블라인드'
                                    } 하시겠습니까?`,
                          uri: `/api/v1/product/reviews/${record.id}/images/blind?imageUrl=${image.imageUrl}`,
                          blinded: image.blinded,
                          onAfterBlind: 
                                        () => {
                                            search()
                                            fetchResource()
                                        }
                                    
                        }, null, 8, ["title", "uri", "blinded", "onAfterBlind"])
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "상품명" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.shoppingProductName), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "상품 ID" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.shoppingProductId), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "상품 옵션 ID" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.shoppingProductOptionId), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, {
            label: "상품 이미지",
            span: 3
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_image_preview_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_image, {
                    style: {"margin-right":"6px"},
                    width: 256,
                    src: record.shoppingProductThumbnailUrl1
                  }, null, 8, ["src"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "상태" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.status), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, {
            label: "블라인드 처리자",
            span: 2
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.blindedBy), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "주문 수" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.orderCount), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, {
            label: "주문 번호",
            span: 2
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.orderItemId), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["filter-spec", "row-selection", "columns"]))
}