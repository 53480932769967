import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7674c435"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-event" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_CreateFormModal = _resolveComponent("CreateFormModal")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ad_current_instance = _resolveComponent("ad-current-instance")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_ad_instance = _resolveComponent("ad-instance")!
  const _component_ad_benefit_slot_tab = _resolveComponent("ad-benefit-slot-tab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "overlay"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_spin)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_a_row, { class: "slot-form" }, {
      default: _withCtx(() => [
        _createVNode(_component_CreateFormModal, {
          title: "섹션 추가",
          label: "섹션 추가",
          uri: '/api/advertisements/slots/benefit',
          "input-spec": _ctx.createSpec,
          rules: _ctx.createRules,
          onAfterSubmit: _ctx.init
        }, null, 8, ["input-spec", "rules", "onAfterSubmit"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { class: "slot" }, {
      default: _withCtx(() => [
        _createVNode(_component_ad_benefit_slot_tab, {
          "props-slot": _ctx.slot,
          "props-slot-id": _ctx.slotId,
          "props-slot-list": _ctx.slotList,
          "props-status": _ctx.status,
          "props-min-build-number-list": _ctx.minBuildNumberList,
          "onChange:slot": _ctx.changeSlot
        }, {
          advertise: _withCtx(() => [
            (_ctx.slotId)
              ? (_openBlock(), _createBlock(_component_a_row, {
                  key: 0,
                  class: "advertise"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      class: "advertise-on",
                      span: 6
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_a_button, {
                            type: "primary",
                            class: "btn-save",
                            onClick: _ctx.save
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" 광고 저장 ")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _createVNode(_component_ad_current_instance, {
                          header: '기본광고',
                          instances: _ctx.baseInstances,
                          onRemove: _ctx.removeBaseInstance
                        }, null, 8, ["instances", "onRemove"]),
                        _createVNode(_component_ad_current_instance, {
                          header: '광고',
                          instances: _ctx.instances,
                          onRemove: _ctx.removeInstances
                        }, null, 8, ["instances", "onRemove"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, {
                      class: "advertise-table",
                      span: 18
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ad_instance, {
                          "slot-id": _ctx.slotId,
                          "min-build-number": 1,
                          "onUpdate:instances": _ctx.updateInstances,
                          "onUpdate:baseInstance": _ctx.updateBaseInstances
                        }, null, 8, ["slot-id", "onUpdate:instances", "onUpdate:baseInstance"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["props-slot", "props-slot-id", "props-slot-list", "props-status", "props-min-build-number-list", "onChange:slot"])
      ]),
      _: 1
    })
  ], 64))
}