<template>
    <a-modal
        :wrap-class-name="(expand && 'full-modal') || null"
        :title="title"
        v-model:visible="visible"
        :footer="null"
        :width="(expand && '100%') || '80em'"
        @cancel="onClose"
    >
        <slot
            name="header"
            :record="record"
            :resource-ids="resourceIds"
            :resource-id="resourceId"
        />
        <span style="float: right">
            <a-button v-if="!expand" @click="toggleExpand">
                <template #icon>
                    <fullscreen-outlined />
                </template>
            </a-button>
            <a-button v-else-if="expand" @click="toggleExpand">
                <template #icon>
                    <fullscreen-exit-outlined />
                </template>
            </a-button>
        </span>
        <UpdateForm
            ref="form"
            :uri="uri"
            :resource-id="resourceId"
            :input-spec="inputSpec"
            :rules="rules"
        />
        <slot
            name="footer"
            :record="record"
            :resource-ids="resourceIds"
            :resource-id="resourceId"
        />
    </a-modal>
</template>
<script setup lang="ts">
import UpdateForm from '../UpdateForm.vue'
import { ref } from 'vue'
import {
    FullscreenOutlined,
    FullscreenExitOutlined,
} from '@ant-design/icons-vue'
import router from '@/router'

defineProps({
    uri: {
        type: String,
        required: true,
    },
    resourceIds: {
        type: Array,
    },
    resourceId: {
        type: [Number, String],
    },
    record: {
        type: Object,
    },
    title: {
        type: String,
        default: '',
    },
    label: {
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: Object,
    },
    icon: {
        type: String,
    },
    inputSpec: {
        type: Object,
        default: () => ({}),
    },
})

const visible = ref(true)
const expand = ref(false)
const form = ref(null)

defineExpose({ form })

const toggleExpand = () => {
    expand.value = !expand.value
}

defineEmits(['submit', 'afterShow', 'afterSubmit', 'resetForm'])

const onClose = () => {
    visible.value = !visible.value
    router.back()
}
</script>
