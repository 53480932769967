import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_form_item = _resolveComponent("form-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form_item_rest = _resolveComponent("a-form-item-rest")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_enum_select = _resolveComponent("enum-select")!
  const _component_question_image_input = _resolveComponent("question-image-input")!
  const _component_product_nutrient_input = _resolveComponent("product-nutrient-input")!
  const _component_search_ad_product_input = _resolveComponent("search-ad-product-input")!
  const _component_food_nutrient_input = _resolveComponent("food-nutrient-input")!
  const _component_survey_option_input = _resolveComponent("survey-option-input")!
  const _component_event_content_input = _resolveComponent("event-content-input")!
  const _component_food_intake_unit_input = _resolveComponent("food-intake-unit-input")!
  const _component_nutrient_input = _resolveComponent("nutrient-input")!
  const _component_boolean_input = _resolveComponent("boolean-input")!
  const _component_product_tag_input = _resolveComponent("product-tag-input")!
  const _component_preset_input = _resolveComponent("preset-input")!
  const _component_auto_complete_input = _resolveComponent("auto-complete-input")!
  const _component_standard_unit_input = _resolveComponent("standard-unit-input")!
  const _component_user_input = _resolveComponent("user-input")!
  const _component_tiptap = _resolveComponent("tiptap")!
  const _component_product_input = _resolveComponent("product-input")!
  const _component_workout_input = _resolveComponent("workout-input")!
  const _component_food_input = _resolveComponent("food-input")!
  const _component_multiple_product_input = _resolveComponent("multiple-product-input")!
  const _component_image_input = _resolveComponent("image-input")!
  const _component_content_image_input = _resolveComponent("content-image-input")!
  const _component_food_intake_input = _resolveComponent("food-intake-input")!
  const _component_food_ingredient_input = _resolveComponent("food-ingredient-input")!
  const _component_template_input = _resolveComponent("template-input")!
  const _component_chat_message_input = _resolveComponent("chat-message-input")!
  const _component_ad_property_input = _resolveComponent("ad-property-input")!
  const _component_ad_slot_input = _resolveComponent("ad-slot-input")!
  const _component_ad_layout_input = _resolveComponent("ad-layout-input")!
  const _component_ad_layout_input_v2 = _resolveComponent("ad-layout-input-v2")!
  const _component_ad_event_handler_input = _resolveComponent("ad-event-handler-input")!
  const _component_ad_vo_input = _resolveComponent("ad-vo-input")!
  const _component_ad_mission_input = _resolveComponent("ad-mission-input")!
  const _component_ad_target_user_input = _resolveComponent("ad-target-user-input")!
  const _component_ad_schedule_input = _resolveComponent("ad-schedule-input")!
  const _component_ad_requirement_input = _resolveComponent("ad-requirement-input")!
  const _component_crm_replacement_input = _resolveComponent("crm-replacement-input")!
  const _component_crm_bizm_button_input = _resolveComponent("crm-bizm-button-input")!
  const _component_input_complete_input = _resolveComponent("input-complete-input")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_copy_outlined = _resolveComponent("copy-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (!_ctx.hidden)
    ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: "24" }, {
            default: _withCtx(() => [
              (_ctx.inputSpec.readonly === true)
                ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 0 }, _ctx.inputSpec, {
                    label: _ctx.label,
                    "label-col": { span: _ctx.labelCol },
                    "wrapper-col": { span: _ctx.wrapperCol },
                    name: _ctx.prop,
                    help: _ctx.inputSpec.help,
                    color: _ctx.inputSpec.color,
                    hidden: _ctx.hidden
                  }), {
                    default: _withCtx(() => [
                      (_ctx.value && _ctx.value.length && typeof _ctx.value !== 'string')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (tag) => {
                              return (_openBlock(), _createBlock(_component_a_tag, {
                                key: tag,
                                color: "cyan"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(tag), 1)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.value), 1))
                    ]),
                    _: 1
                  }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                : (_ctx.inputSpec.component === 'AInputNumber')
                  ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 1 }, _ctx.inputSpec, {
                      label: _ctx.label,
                      "label-col": { span: _ctx.labelCol },
                      "wrapper-col": { span: _ctx.wrapperCol },
                      name: _ctx.prop,
                      help: _ctx.inputSpec.help,
                      hidden: _ctx.hidden
                    }), {
                      default: _withCtx(() => [
                        (!_ctx.infinite)
                          ? (_openBlock(), _createBlock(_component_a_input_number, _mergeProps({ key: 0 }, _ctx.inputSpec, {
                              value: _ctx.value,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
                              min: 0,
                              disabled: _ctx.disabled,
                              hidden: _ctx.hidden,
                              onChange: _ctx.inputSpec.input,
                              onKeyup: _withKeys(_ctx.checkKeyup, ["enter"]),
                              style: { width: _ctx.inputSpec.width || 'fit-content' }
                            }), null, 16, ["value", "disabled", "hidden", "onChange", "onKeyup", "style"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_a_form_item_rest, null, {
                          default: _withCtx(() => [
                            (_ctx.inputSpec.infinity)
                              ? (_openBlock(), _createBlock(_component_a_switch, {
                                  key: 0,
                                  checked: _ctx.infinite,
                                  "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.infinite) = $event)),
                                  "checked-children": "무한",
                                  "un-checked-children": "0",
                                  onClick: _cache[2] || (_cache[2] = 
                            (checked) => {
                                if (checked) {
                                    _ctx.setValue(9999999999999.0)
                                    _ctx.infinite = true
                                } else {
                                    _ctx.setValue(null)
                                    _ctx.infinite = false
                                }
                            }
                        ),
                                  style: {"margin-left":"8px"}
                                }, null, 8, ["checked"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 16, ["label", "label-col", "wrapper-col", "name", "help", "hidden"]))
                  : (_ctx.inputSpec.component === 'ASelect')
                    ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 2 }, _ctx.inputSpec, {
                        label: _ctx.label,
                        "label-col": { span: _ctx.labelCol },
                        "wrapper-col": { span: _ctx.wrapperCol },
                        name: _ctx.prop,
                        help: _ctx.inputSpec.help,
                        color: _ctx.inputSpec.color,
                        hidden: _ctx.hidden
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, _mergeProps(_ctx.inputSpec, {
                            value: _ctx.value,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
                            disabled: _ctx.disabled,
                            options: _ctx.inputSpec.options,
                            style: { width: _ctx.inputSpec.width || '8em' }
                          }), {
                            option: _withCtx(({ label, image }) => [
                              _createElementVNode("span", {
                                role: "img",
                                "aria-label": label
                              }, _toDisplayString(image), 9, _hoisted_3),
                              _createTextVNode("   " + _toDisplayString(label), 1)
                            ]),
                            _: 1
                          }, 16, ["value", "disabled", "options", "style"])
                        ]),
                        _: 1
                      }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                    : (_ctx.inputSpec.component === 'ARangePicker')
                      ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 3 }, _ctx.inputSpec, {
                          label: _ctx.label,
                          "label-col": { span: _ctx.labelCol },
                          "wrapper-col": { span: _ctx.wrapperCol },
                          name: _ctx.prop,
                          help: _ctx.inputSpec.help,
                          color: _ctx.inputSpec.color,
                          hidden: _ctx.hidden
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_a_range_picker, _mergeProps(_ctx.inputSpec, {
                              value: _ctx.value,
                              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value) = $event)),
                              "value-format": "YYYY-MM-DD HH:mm:ss",
                              disabled: _ctx.disabled,
                              onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                            }), null, 16, ["value", "disabled"])
                          ]),
                          _: 1
                        }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                      : (_ctx.inputSpec.component === 'ARangeDatePicker')
                        ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 4 }, _ctx.inputSpec, {
                            label: _ctx.label,
                            "label-col": { span: _ctx.labelCol },
                            "wrapper-col": { span: _ctx.wrapperCol },
                            name: _ctx.prop,
                            help: _ctx.inputSpec.help,
                            color: _ctx.inputSpec.color,
                            hidden: _ctx.hidden
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_a_range_picker, _mergeProps(_ctx.inputSpec, {
                                value: _ctx.value,
                                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.value) = $event)),
                                "value-format": "YYYY-MM-DD",
                                disabled: _ctx.disabled,
                                onKeyup: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                              }), null, 16, ["value", "disabled"])
                            ]),
                            _: 1
                          }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                        : (_ctx.inputSpec.component === 'ADatePicker')
                          ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 5 }, _ctx.inputSpec, {
                              label: _ctx.label,
                              "label-col": { span: _ctx.labelCol },
                              "wrapper-col": { span: _ctx.wrapperCol },
                              name: _ctx.prop,
                              help: _ctx.inputSpec.help,
                              color: _ctx.inputSpec.color,
                              hidden: _ctx.hidden
                            }), {
                              default: _withCtx(() => [
                                _createVNode(_component_a_date_picker, _mergeProps(_ctx.inputSpec, {
                                  value: _ctx.value,
                                  "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.value) = $event)),
                                  "value-format": "YYYY-MM-DD",
                                  disabled: _ctx.disabled,
                                  onKeyup: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                }), null, 16, ["value", "disabled"])
                              ]),
                              _: 1
                            }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                          : (_ctx.inputSpec.component === 'ADatetimePicker')
                            ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 6 }, _ctx.inputSpec, {
                                label: _ctx.label,
                                "label-col": { span: _ctx.labelCol },
                                "wrapper-col": { span: _ctx.wrapperCol },
                                name: _ctx.prop,
                                help: _ctx.inputSpec.help,
                                color: _ctx.inputSpec.color,
                                hidden: _ctx.hidden
                              }), {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_date_picker, _mergeProps(_ctx.inputSpec, {
                                    value: _ctx.value,
                                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.value) = $event)),
                                    "show-time": "",
                                    "value-format": _ctx.inputSpec.format || 'YYYY-MM-DDTHH:mm:ss',
                                    disabled: _ctx.disabled,
                                    onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                  }), null, 16, ["value", "value-format", "disabled"])
                                ]),
                                _: 1
                              }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                            : (_ctx.inputSpec.component === 'EnumSelect')
                              ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 7 }, _ctx.inputSpec, {
                                  label: _ctx.label,
                                  "label-col": { span: _ctx.labelCol },
                                  "wrapper-col": { span: _ctx.wrapperCol },
                                  name: _ctx.prop,
                                  help: _ctx.inputSpec.help,
                                  color: _ctx.inputSpec.color,
                                  hidden: _ctx.hidden
                                }), {
                                  default: _withCtx(() => [
                                    _createVNode(_component_enum_select, _mergeProps(_ctx.inputSpec, {
                                      value: _ctx.value,
                                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.value) = $event)),
                                      "type-name": _ctx.inputSpec.typeName,
                                      disabled: _ctx.disabled,
                                      onKeyup: _cache[13] || (_cache[13] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"])),
                                      onInput: _ctx.inputSpec.input,
                                      onSelect: _ctx.inputSpec.select
                                    }), null, 16, ["value", "type-name", "disabled", "onInput", "onSelect"])
                                  ]),
                                  _: 1
                                }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                              : (_ctx.inputSpec.component === 'QuestionImageInput')
                                ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 8 }, _ctx.inputSpec, {
                                    label: _ctx.label,
                                    "label-col": { span: _ctx.labelCol },
                                    "wrapper-col": { span: _ctx.wrapperCol },
                                    name: _ctx.prop,
                                    help: _ctx.inputSpec.help,
                                    color: _ctx.inputSpec.color,
                                    hidden: _ctx.hidden
                                  }), {
                                    default: _withCtx(() => [
                                      _createVNode(_component_question_image_input, _mergeProps(_ctx.inputSpec, {
                                        value: _ctx.value,
                                        "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.value) = $event)),
                                        "type-name": _ctx.inputSpec.typeName,
                                        disabled: _ctx.disabled,
                                        onKeyup: _cache[15] || (_cache[15] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"])),
                                        onInput: _ctx.inputSpec.input,
                                        onSelect: _ctx.inputSpec.select,
                                        "resource-id": _ctx.resourceId
                                      }), null, 16, ["value", "type-name", "disabled", "onInput", "onSelect", "resource-id"])
                                    ]),
                                    _: 1
                                  }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                : (_ctx.inputSpec.component === 'ProductNutrientInput')
                                  ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 9 }, _ctx.inputSpec, {
                                      label: _ctx.label,
                                      "label-col": { span: _ctx.labelCol },
                                      "wrapper-col": { span: _ctx.wrapperCol },
                                      name: _ctx.prop,
                                      help: _ctx.inputSpec.help,
                                      color: _ctx.inputSpec.color,
                                      hidden: _ctx.hidden
                                    }), {
                                      default: _withCtx(() => [
                                        _createVNode(_component_product_nutrient_input, _mergeProps(_ctx.inputSpec, {
                                          value: _ctx.value,
                                          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.value) = $event)),
                                          disabled: _ctx.disabled,
                                          onKeyup: _cache[17] || (_cache[17] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                        }), null, 16, ["value", "disabled"])
                                      ]),
                                      _: 1
                                    }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                  : (_ctx.inputSpec.component === 'SearchAdProductInput')
                                    ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 10 }, _ctx.inputSpec, {
                                        label: _ctx.label,
                                        "label-col": { span: _ctx.labelCol },
                                        "wrapper-col": { span: _ctx.wrapperCol },
                                        name: _ctx.prop,
                                        help: _ctx.inputSpec.help,
                                        color: _ctx.inputSpec.color,
                                        hidden: _ctx.hidden
                                      }), {
                                        default: _withCtx(() => [
                                          _createVNode(_component_search_ad_product_input, _mergeProps(_ctx.inputSpec, {
                                            value: _ctx.value,
                                            "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.value) = $event)),
                                            disabled: _ctx.disabled,
                                            onKeyup: _cache[19] || (_cache[19] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                          }), null, 16, ["value", "disabled"])
                                        ]),
                                        _: 1
                                      }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                    : (_ctx.inputSpec.component === 'FoodNutrientInput')
                                      ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 11 }, _ctx.inputSpec, {
                                          label: _ctx.label,
                                          "label-col": { span: _ctx.labelCol },
                                          "wrapper-col": { span: _ctx.wrapperCol },
                                          name: _ctx.prop,
                                          help: _ctx.inputSpec.help,
                                          color: _ctx.inputSpec.color,
                                          hidden: _ctx.hidden
                                        }), {
                                          default: _withCtx(() => [
                                            _createVNode(_component_food_nutrient_input, _mergeProps(_ctx.inputSpec, {
                                              value: _ctx.value,
                                              "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.value) = $event)),
                                              disabled: _ctx.disabled,
                                              onKeyup: _cache[21] || (_cache[21] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                            }), null, 16, ["value", "disabled"])
                                          ]),
                                          _: 1
                                        }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                      : (_ctx.inputSpec.component === 'SurveyOptionInput')
                                        ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 12 }, _ctx.inputSpec, {
                                            label: _ctx.label,
                                            "label-col": { span: _ctx.labelCol },
                                            "wrapper-col": { span: _ctx.wrapperCol },
                                            name: _ctx.prop,
                                            help: _ctx.inputSpec.help,
                                            color: _ctx.inputSpec.color,
                                            hidden: _ctx.hidden
                                          }), {
                                            default: _withCtx(() => [
                                              _createVNode(_component_survey_option_input, _mergeProps(_ctx.inputSpec, {
                                                value: _ctx.value,
                                                "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.value) = $event)),
                                                disabled: _ctx.disabled,
                                                onKeyup: _cache[23] || (_cache[23] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                              }), null, 16, ["value", "disabled"])
                                            ]),
                                            _: 1
                                          }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                        : (_ctx.inputSpec.component === 'EventContentInput')
                                          ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 13 }, _ctx.inputSpec, {
                                              label: _ctx.label,
                                              "label-col": { span: _ctx.labelCol },
                                              "wrapper-col": { span: _ctx.wrapperCol },
                                              name: _ctx.prop,
                                              help: _ctx.inputSpec.help,
                                              color: _ctx.inputSpec.color,
                                              hidden: _ctx.hidden
                                            }), {
                                              default: _withCtx(() => [
                                                _createVNode(_component_event_content_input, _mergeProps(_ctx.inputSpec, {
                                                  value: _ctx.value,
                                                  "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.value) = $event)),
                                                  disabled: _ctx.disabled,
                                                  "resource-id": _ctx.resourceId
                                                }), null, 16, ["value", "disabled", "resource-id"])
                                              ]),
                                              _: 1
                                            }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                          : (_ctx.inputSpec.component === 'FoodIntakeUnitInput')
                                            ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 14 }, _ctx.inputSpec, {
                                                label: _ctx.label,
                                                "label-col": { span: _ctx.labelCol },
                                                "wrapper-col": { span: _ctx.wrapperCol },
                                                name: _ctx.prop,
                                                help: _ctx.inputSpec.help,
                                                color: _ctx.inputSpec.color,
                                                hidden: _ctx.hidden
                                              }), {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_food_intake_unit_input, _mergeProps(_ctx.inputSpec, {
                                                    value: _ctx.value,
                                                    "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.value) = $event)),
                                                    disabled: _ctx.disabled,
                                                    onKeyup: _cache[26] || (_cache[26] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                  }), null, 16, ["value", "disabled"])
                                                ]),
                                                _: 1
                                              }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                            : (_ctx.inputSpec.component === 'NutrientInput')
                                              ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 15 }, _ctx.inputSpec, {
                                                  label: _ctx.label,
                                                  "label-col": { span: _ctx.labelCol },
                                                  "wrapper-col": { span: _ctx.wrapperCol },
                                                  name: _ctx.prop,
                                                  help: _ctx.inputSpec.help,
                                                  color: _ctx.inputSpec.color,
                                                  hidden: _ctx.hidden
                                                }), {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_nutrient_input, _mergeProps(_ctx.inputSpec, {
                                                      value: _ctx.value,
                                                      "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.value) = $event)),
                                                      disabled: _ctx.disabled,
                                                      onSelect: _ctx.inputSpec.select,
                                                      onKeyup: _cache[28] || (_cache[28] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                    }), null, 16, ["value", "disabled", "onSelect"])
                                                  ]),
                                                  _: 1
                                                }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                              : (_ctx.inputSpec.component === 'BooleanInput')
                                                ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 16 }, _ctx.inputSpec, {
                                                    label: _ctx.label,
                                                    "label-col": { span: _ctx.labelCol },
                                                    "wrapper-col": { span: _ctx.wrapperCol },
                                                    name: _ctx.prop,
                                                    help: _ctx.inputSpec.help,
                                                    color: _ctx.inputSpec.color,
                                                    hidden: _ctx.hidden
                                                  }), {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_boolean_input, _mergeProps(_ctx.inputSpec, {
                                                        value: _ctx.value,
                                                        "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.value) = $event)),
                                                        disabled: _ctx.disabled,
                                                        onKeyup: _cache[30] || (_cache[30] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                      }), null, 16, ["value", "disabled"])
                                                    ]),
                                                    _: 1
                                                  }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                : (_ctx.inputSpec.component === 'ProductTagInput')
                                                  ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 17 }, _ctx.inputSpec, {
                                                      label: _ctx.label,
                                                      "label-col": { span: _ctx.labelCol },
                                                      "wrapper-col": { span: _ctx.wrapperCol },
                                                      name: _ctx.prop,
                                                      help: _ctx.inputSpec.help,
                                                      color: _ctx.inputSpec.color,
                                                      hidden: _ctx.hidden
                                                    }), {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_product_tag_input, _mergeProps(_ctx.inputSpec, {
                                                          value: _ctx.value,
                                                          "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.value) = $event)),
                                                          disabled: _ctx.disabled,
                                                          onSelect: _ctx.inputSpec.select,
                                                          onDeselect: _ctx.inputSpec.deselect,
                                                          onKeyup: _cache[32] || (_cache[32] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                        }), null, 16, ["value", "disabled", "onSelect", "onDeselect"])
                                                      ]),
                                                      _: 1
                                                    }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                  : (_ctx.inputSpec.component === 'PresetInput')
                                                    ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 18 }, _ctx.inputSpec, {
                                                        label: _ctx.label,
                                                        "label-col": { span: _ctx.labelCol },
                                                        "wrapper-col": { span: _ctx.wrapperCol },
                                                        name: _ctx.prop,
                                                        help: _ctx.inputSpec.help,
                                                        color: _ctx.inputSpec.color,
                                                        hidden: _ctx.hidden
                                                      }), {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_preset_input, _mergeProps(_ctx.inputSpec, {
                                                            value: _ctx.value,
                                                            disabled: _ctx.disabled,
                                                            radio: _ctx.inputSpec.radio,
                                                            onCheck: _ctx.inputSpec.input,
                                                            onKeyup: _cache[33] || (_cache[33] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                          }), null, 16, ["value", "disabled", "radio", "onCheck"])
                                                        ]),
                                                        _: 1
                                                      }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                    : (_ctx.inputSpec.component === 'AutoCompleteInput')
                                                      ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 19 }, _ctx.inputSpec, {
                                                          label: _ctx.label,
                                                          "label-col": { span: _ctx.labelCol },
                                                          "wrapper-col": { span: _ctx.wrapperCol },
                                                          name: _ctx.prop,
                                                          help: _ctx.inputSpec.help,
                                                          color: _ctx.inputSpec.color,
                                                          hidden: _ctx.hidden
                                                        }), {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_auto_complete_input, _mergeProps(_ctx.inputSpec, {
                                                              name: _ctx.inputSpec.name,
                                                              uri: _ctx.inputSpec.uri,
                                                              value: _ctx.value,
                                                              "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.value) = $event)),
                                                              disabled: _ctx.disabled,
                                                              onSelect: _ctx.inputSpec.select,
                                                              onKeyup: _cache[35] || (_cache[35] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                            }), null, 16, ["name", "uri", "value", "disabled", "onSelect"])
                                                          ]),
                                                          _: 1
                                                        }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                      : (_ctx.inputSpec.component === 'StandardUnitInput')
                                                        ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 20 }, _ctx.inputSpec, {
                                                            label: _ctx.label,
                                                            "label-col": { span: _ctx.labelCol },
                                                            "wrapper-col": { span: _ctx.wrapperCol },
                                                            name: _ctx.prop,
                                                            help: _ctx.inputSpec.help,
                                                            color: _ctx.inputSpec.color,
                                                            hidden: _ctx.hidden
                                                          }), {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_standard_unit_input, _mergeProps(_ctx.inputSpec, {
                                                                value: _ctx.value,
                                                                "onUpdate:value": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.value) = $event)),
                                                                disabled: _ctx.disabled,
                                                                onSelect: _ctx.inputSpec.select,
                                                                onKeyup: _cache[37] || (_cache[37] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                              }), null, 16, ["value", "disabled", "onSelect"])
                                                            ]),
                                                            _: 1
                                                          }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                        : (_ctx.inputSpec.component === 'UserInput')
                                                          ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 21 }, _ctx.inputSpec, {
                                                              label: _ctx.label,
                                                              "label-col": { span: _ctx.labelCol },
                                                              "wrapper-col": { span: _ctx.wrapperCol },
                                                              name: _ctx.prop,
                                                              help: _ctx.inputSpec.help,
                                                              color: _ctx.inputSpec.color,
                                                              hidden: _ctx.hidden
                                                            }), {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_user_input, _mergeProps(_ctx.inputSpec, {
                                                                  value: _ctx.value,
                                                                  "onUpdate:value": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.value) = $event)),
                                                                  disabled: _ctx.disabled,
                                                                  onKeyup: _cache[39] || (_cache[39] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                }), null, 16, ["value", "disabled"])
                                                              ]),
                                                              _: 1
                                                            }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                          : (_ctx.inputSpec.component === 'Tiptap')
                                                            ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 22 }, _ctx.inputSpec, {
                                                                label: _ctx.label,
                                                                "label-col": { span: _ctx.labelCol },
                                                                "wrapper-col": { span: _ctx.wrapperCol },
                                                                name: _ctx.prop,
                                                                help: _ctx.inputSpec.help,
                                                                color: _ctx.inputSpec.color,
                                                                hidden: _ctx.hidden
                                                              }), {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_tiptap, {
                                                                    value: _ctx.value,
                                                                    "onUpdate:value": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.value) = $event))
                                                                  }, null, 8, ["value"])
                                                                ]),
                                                                _: 1
                                                              }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                            : (_ctx.inputSpec.component === 'ProductInput')
                                                              ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 23 }, _ctx.inputSpec, {
                                                                  label: _ctx.label,
                                                                  "label-col": { span: _ctx.labelCol },
                                                                  "wrapper-col": { span: _ctx.wrapperCol },
                                                                  name: _ctx.prop,
                                                                  help: _ctx.inputSpec.help,
                                                                  color: _ctx.inputSpec.color,
                                                                  hidden: _ctx.hidden
                                                                }), {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_product_input, _mergeProps(_ctx.inputSpec, {
                                                                      value: _ctx.value,
                                                                      "onUpdate:value": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.value) = $event)),
                                                                      onSelect: _ctx.inputSpec.select,
                                                                      disabled: _ctx.disabled
                                                                    }), null, 16, ["value", "onSelect", "disabled"])
                                                                  ]),
                                                                  _: 1
                                                                }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                              : (_ctx.inputSpec.component === 'WorkoutInput')
                                                                ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 24 }, _ctx.inputSpec, {
                                                                    label: _ctx.label,
                                                                    "label-col": { span: _ctx.labelCol },
                                                                    "wrapper-col": { span: _ctx.wrapperCol },
                                                                    name: _ctx.prop,
                                                                    help: _ctx.inputSpec.help,
                                                                    color: _ctx.inputSpec.color,
                                                                    hidden: _ctx.hidden
                                                                  }), {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_workout_input, _mergeProps(_ctx.inputSpec, {
                                                                        value: _ctx.value,
                                                                        "onUpdate:value": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.value) = $event)),
                                                                        onSelect: _ctx.inputSpec.select,
                                                                        disabled: _ctx.disabled
                                                                      }), null, 16, ["value", "onSelect", "disabled"])
                                                                    ]),
                                                                    _: 1
                                                                  }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                : (_ctx.inputSpec.component === 'FoodInput')
                                                                  ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 25 }, _ctx.inputSpec, {
                                                                      label: _ctx.label,
                                                                      "label-col": { span: _ctx.labelCol },
                                                                      "wrapper-col": { span: _ctx.wrapperCol },
                                                                      name: _ctx.prop,
                                                                      help: _ctx.inputSpec.help,
                                                                      color: _ctx.inputSpec.color,
                                                                      hidden: _ctx.hidden
                                                                    }), {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_food_input, _mergeProps(_ctx.inputSpec, {
                                                                          value: _ctx.value,
                                                                          "onUpdate:value": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.value) = $event)),
                                                                          onSelect: _ctx.inputSpec.select,
                                                                          disabled: _ctx.disabled
                                                                        }), null, 16, ["value", "onSelect", "disabled"])
                                                                      ]),
                                                                      _: 1
                                                                    }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                  : (_ctx.inputSpec.component === 'MultipleProductInput')
                                                                    ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 26 }, _ctx.inputSpec, {
                                                                        label: _ctx.label,
                                                                        "label-col": { span: _ctx.labelCol },
                                                                        "wrapper-col": { span: _ctx.wrapperCol },
                                                                        name: _ctx.prop,
                                                                        help: _ctx.inputSpec.help,
                                                                        color: _ctx.inputSpec.color,
                                                                        hidden: _ctx.hidden
                                                                      }), {
                                                                        default: _withCtx(() => [
                                                                          _createVNode(_component_multiple_product_input, _mergeProps(_ctx.inputSpec, {
                                                                            value: _ctx.value,
                                                                            "onUpdate:value": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.value) = $event)),
                                                                            onSelect: _ctx.inputSpec.select,
                                                                            disabled: _ctx.disabled
                                                                          }), null, 16, ["value", "onSelect", "disabled"])
                                                                        ]),
                                                                        _: 1
                                                                      }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                    : (_ctx.inputSpec.component === 'ImageInput')
                                                                      ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 27 }, _ctx.inputSpec, {
                                                                          label: _ctx.label,
                                                                          "label-col": { span: _ctx.labelCol },
                                                                          "wrapper-col": { span: _ctx.wrapperCol },
                                                                          name: _ctx.prop,
                                                                          help: _ctx.inputSpec.help,
                                                                          color: _ctx.inputSpec.color,
                                                                          hidden: _ctx.hidden
                                                                        }), {
                                                                          default: _withCtx(() => [
                                                                            _createVNode(_component_image_input, _mergeProps(_ctx.inputSpec, {
                                                                              value: _ctx.value,
                                                                              "onUpdate:value": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.value) = $event)),
                                                                              onSelect: _ctx.inputSpec.select,
                                                                              disabled: _ctx.disabled,
                                                                              form: _ctx.form
                                                                            }), null, 16, ["value", "onSelect", "disabled", "form"])
                                                                          ]),
                                                                          _: 1
                                                                        }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                      : (_ctx.inputSpec.component === 'ContentImageInput')
                                                                        ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 28 }, _ctx.inputSpec, {
                                                                            label: _ctx.label,
                                                                            "label-col": { span: _ctx.labelCol },
                                                                            "wrapper-col": { span: _ctx.wrapperCol },
                                                                            name: _ctx.prop,
                                                                            help: _ctx.inputSpec.help,
                                                                            color: _ctx.inputSpec.color,
                                                                            style: {"display":"flex","align-items":"center"},
                                                                            hidden: _ctx.hidden
                                                                          }), {
                                                                            default: _withCtx(() => [
                                                                              _createVNode(_component_content_image_input, _mergeProps(_ctx.inputSpec, {
                                                                                value: _ctx.value,
                                                                                "onUpdate:value": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.value) = $event)),
                                                                                onSelect: _ctx.inputSpec.select,
                                                                                disabled: _ctx.disabled,
                                                                                style: {"display":"flex","align-items":"center"}
                                                                              }), null, 16, ["value", "onSelect", "disabled"])
                                                                            ]),
                                                                            _: 1
                                                                          }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                        : (_ctx.inputSpec.component === 'FoodIntakeInput')
                                                                          ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 29 }, _ctx.inputSpec, {
                                                                              label: _ctx.label,
                                                                              "label-col": { span: _ctx.labelCol },
                                                                              "wrapper-col": { span: _ctx.wrapperCol },
                                                                              name: _ctx.prop,
                                                                              help: _ctx.inputSpec.help,
                                                                              color: _ctx.inputSpec.color,
                                                                              hidden: _ctx.hidden
                                                                            }), {
                                                                              default: _withCtx(() => [
                                                                                _createVNode(_component_food_intake_input, _mergeProps(_ctx.inputSpec, {
                                                                                  value: _ctx.value,
                                                                                  "onUpdate:value": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.value) = $event)),
                                                                                  onSelect: _ctx.inputSpec.select,
                                                                                  disabled: _ctx.disabled
                                                                                }), null, 16, ["value", "onSelect", "disabled"])
                                                                              ]),
                                                                              _: 1
                                                                            }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                          : (_ctx.inputSpec.component === 'FoodIngredientInput')
                                                                            ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 30 }, _ctx.inputSpec, {
                                                                                label: _ctx.label,
                                                                                "label-col": { span: _ctx.labelCol },
                                                                                "wrapper-col": { span: _ctx.wrapperCol },
                                                                                name: _ctx.prop,
                                                                                help: _ctx.inputSpec.help,
                                                                                color: _ctx.inputSpec.color,
                                                                                hidden: _ctx.hidden
                                                                              }), {
                                                                                default: _withCtx(() => [
                                                                                  _createVNode(_component_food_ingredient_input, _mergeProps(_ctx.inputSpec, {
                                                                                    value: _ctx.value,
                                                                                    "onUpdate:value": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.value) = $event)),
                                                                                    onChange: _ctx.inputSpec.input,
                                                                                    disabled: _ctx.disabled
                                                                                  }), null, 16, ["value", "onChange", "disabled"])
                                                                                ]),
                                                                                _: 1
                                                                              }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                            : (_ctx.inputSpec.component === 'TemplateInput')
                                                                              ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 31 }, _ctx.inputSpec, {
                                                                                  label: _ctx.label,
                                                                                  "label-col": { span: _ctx.labelCol },
                                                                                  "wrapper-col": { span: _ctx.wrapperCol },
                                                                                  name: _ctx.prop,
                                                                                  help: _ctx.inputSpec.help,
                                                                                  color: _ctx.inputSpec.color,
                                                                                  hidden: _ctx.hidden
                                                                                }), {
                                                                                  default: _withCtx(() => [
                                                                                    _createVNode(_component_template_input, _mergeProps(_ctx.inputSpec, {
                                                                                      value: _ctx.value,
                                                                                      "onUpdate:value": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.value) = $event)),
                                                                                      onChange: _ctx.inputSpec.input,
                                                                                      disabled: _ctx.disabled
                                                                                    }), null, 16, ["value", "onChange", "disabled"])
                                                                                  ]),
                                                                                  _: 1
                                                                                }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                              : (_ctx.inputSpec.component === 'ChatMessageInput')
                                                                                ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 32 }, _ctx.inputSpec, {
                                                                                    label: _ctx.label,
                                                                                    "label-col": { span: _ctx.labelCol },
                                                                                    "wrapper-col": { span: _ctx.wrapperCol },
                                                                                    name: _ctx.prop,
                                                                                    help: _ctx.inputSpec.help,
                                                                                    color: _ctx.inputSpec.color,
                                                                                    hidden: _ctx.hidden
                                                                                  }), {
                                                                                    default: _withCtx(() => [
                                                                                      _createVNode(_component_chat_message_input, _mergeProps(_ctx.inputSpec, {
                                                                                        value: _ctx.value,
                                                                                        "onUpdate:value": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.value) = $event)),
                                                                                        onChange: _ctx.inputSpec.input,
                                                                                        disabled: _ctx.disabled
                                                                                      }), null, 16, ["value", "onChange", "disabled"])
                                                                                    ]),
                                                                                    _: 1
                                                                                  }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                                : (_ctx.inputSpec.component === 'AdPropertyInput')
                                                                                  ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 33 }, _ctx.inputSpec, {
                                                                                      label: _ctx.label,
                                                                                      "label-col": { span: _ctx.labelCol },
                                                                                      "wrapper-col": { span: _ctx.wrapperCol },
                                                                                      name: _ctx.prop,
                                                                                      help: _ctx.inputSpec.help,
                                                                                      color: _ctx.inputSpec.color
                                                                                    }), {
                                                                                      default: _withCtx(() => [
                                                                                        _createVNode(_component_ad_property_input, _mergeProps(_ctx.inputSpec, {
                                                                                          value: _ctx.value,
                                                                                          "onUpdate:value": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.value) = $event)),
                                                                                          disabled: _ctx.disabled,
                                                                                          onKeyup: _cache[52] || (_cache[52] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                        }), null, 16, ["value", "disabled"])
                                                                                      ]),
                                                                                      _: 1
                                                                                    }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                  : (_ctx.inputSpec.component === 'AdSlotInput')
                                                                                    ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 34 }, _ctx.inputSpec, {
                                                                                        label: _ctx.label,
                                                                                        "label-col": { span: _ctx.labelCol },
                                                                                        "wrapper-col": { span: _ctx.wrapperCol },
                                                                                        name: _ctx.prop,
                                                                                        help: _ctx.inputSpec.help,
                                                                                        color: _ctx.inputSpec.color
                                                                                      }), {
                                                                                        default: _withCtx(() => [
                                                                                          _createVNode(_component_ad_slot_input, _mergeProps(_ctx.inputSpec, {
                                                                                            value: _ctx.value,
                                                                                            "onUpdate:value": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.value) = $event)),
                                                                                            disabled: _ctx.disabled,
                                                                                            onSelect: _ctx.inputSpec.select,
                                                                                            onKeyup: _cache[54] || (_cache[54] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                          }), null, 16, ["value", "disabled", "onSelect"])
                                                                                        ]),
                                                                                        _: 1
                                                                                      }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                    : (_ctx.inputSpec.component === 'AdLayoutInput')
                                                                                      ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 35 }, _ctx.inputSpec, {
                                                                                          label: _ctx.label,
                                                                                          "label-col": { span: _ctx.labelCol },
                                                                                          "wrapper-col": { span: _ctx.wrapperCol },
                                                                                          name: _ctx.prop,
                                                                                          help: _ctx.inputSpec.help,
                                                                                          color: _ctx.inputSpec.color
                                                                                        }), {
                                                                                          default: _withCtx(() => [
                                                                                            _createVNode(_component_ad_layout_input, _mergeProps(_ctx.inputSpec, {
                                                                                              value: _ctx.value,
                                                                                              "onUpdate:value": _cache[55] || (_cache[55] = ($event: any) => ((_ctx.value) = $event)),
                                                                                              disabled: _ctx.disabled,
                                                                                              onSelect: _ctx.inputSpec.select,
                                                                                              onKeyup: _cache[56] || (_cache[56] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                            }), null, 16, ["value", "disabled", "onSelect"])
                                                                                          ]),
                                                                                          _: 1
                                                                                        }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                      : (_ctx.inputSpec.component === 'AdLayoutInputV2')
                                                                                        ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 36 }, _ctx.inputSpec, {
                                                                                            label: _ctx.label,
                                                                                            "label-col": { span: _ctx.labelCol },
                                                                                            "wrapper-col": { span: _ctx.wrapperCol },
                                                                                            name: _ctx.prop,
                                                                                            help: _ctx.inputSpec.help,
                                                                                            color: _ctx.inputSpec.color
                                                                                          }), {
                                                                                            default: _withCtx(() => [
                                                                                              _createVNode(_component_ad_layout_input_v2, _mergeProps(_ctx.inputSpec, {
                                                                                                value: _ctx.value,
                                                                                                "onUpdate:value": _cache[57] || (_cache[57] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                disabled: _ctx.disabled,
                                                                                                onSelect: _ctx.inputSpec.select,
                                                                                                onKeyup: _cache[58] || (_cache[58] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                              }), null, 16, ["value", "disabled", "onSelect"])
                                                                                            ]),
                                                                                            _: 1
                                                                                          }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                        : (_ctx.inputSpec.component === 'AdEventHandlerInput')
                                                                                          ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 37 }, _ctx.inputSpec, {
                                                                                              label: _ctx.label,
                                                                                              "label-col": { span: _ctx.labelCol },
                                                                                              "wrapper-col": { span: _ctx.wrapperCol },
                                                                                              name: _ctx.prop,
                                                                                              help: _ctx.inputSpec.help,
                                                                                              color: _ctx.inputSpec.color
                                                                                            }), {
                                                                                              default: _withCtx(() => [
                                                                                                _createVNode(_component_ad_event_handler_input, _mergeProps(_ctx.inputSpec, {
                                                                                                  value: _ctx.value,
                                                                                                  "onUpdate:value": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                  disabled: _ctx.disabled,
                                                                                                  onSelect: _ctx.inputSpec.select,
                                                                                                  onKeyup: _cache[60] || (_cache[60] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                                }), null, 16, ["value", "disabled", "onSelect"])
                                                                                              ]),
                                                                                              _: 1
                                                                                            }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                          : (_ctx.inputSpec.component === 'AdVoInput')
                                                                                            ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 38 }, _ctx.inputSpec, {
                                                                                                label: _ctx.label,
                                                                                                "label-col": { span: _ctx.labelCol },
                                                                                                "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                name: _ctx.prop,
                                                                                                help: _ctx.inputSpec.help,
                                                                                                color: _ctx.inputSpec.color
                                                                                              }), {
                                                                                                default: _withCtx(() => [
                                                                                                  _createVNode(_component_ad_vo_input, _mergeProps(_ctx.inputSpec, {
                                                                                                    value: _ctx.value,
                                                                                                    "onUpdate:value": _cache[61] || (_cache[61] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                    disabled: _ctx.disabled,
                                                                                                    onSelect: _ctx.inputSpec.select,
                                                                                                    onKeyup: _cache[62] || (_cache[62] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                                  }), null, 16, ["value", "disabled", "onSelect"])
                                                                                                ]),
                                                                                                _: 1
                                                                                              }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                            : (_ctx.inputSpec.component === 'AdMissionInput')
                                                                                              ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 39 }, _ctx.inputSpec, {
                                                                                                  label: _ctx.label,
                                                                                                  "label-col": { span: _ctx.labelCol },
                                                                                                  "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                  name: _ctx.prop,
                                                                                                  help: _ctx.inputSpec.help,
                                                                                                  color: _ctx.inputSpec.color
                                                                                                }), {
                                                                                                  default: _withCtx(() => [
                                                                                                    _createVNode(_component_ad_mission_input, _mergeProps(_ctx.inputSpec, {
                                                                                                      value: _ctx.value,
                                                                                                      "onUpdate:value": _cache[63] || (_cache[63] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                      disabled: _ctx.disabled,
                                                                                                      onSelect: _ctx.inputSpec.select,
                                                                                                      onKeyup: _cache[64] || (_cache[64] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                                    }), null, 16, ["value", "disabled", "onSelect"])
                                                                                                  ]),
                                                                                                  _: 1
                                                                                                }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                              : (_ctx.inputSpec.component === 'AdTargetUserInput')
                                                                                                ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 40 }, _ctx.inputSpec, {
                                                                                                    label: _ctx.label,
                                                                                                    "label-col": { span: _ctx.labelCol },
                                                                                                    "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                    name: _ctx.prop,
                                                                                                    help: _ctx.inputSpec.help,
                                                                                                    color: _ctx.inputSpec.color
                                                                                                  }), {
                                                                                                    default: _withCtx(() => [
                                                                                                      _createVNode(_component_ad_target_user_input, _mergeProps(_ctx.inputSpec, {
                                                                                                        value: _ctx.value,
                                                                                                        "onUpdate:value": _cache[65] || (_cache[65] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                        disabled: _ctx.disabled,
                                                                                                        onSelect: _ctx.inputSpec.select,
                                                                                                        onKeyup: _cache[66] || (_cache[66] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                                      }), null, 16, ["value", "disabled", "onSelect"])
                                                                                                    ]),
                                                                                                    _: 1
                                                                                                  }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                                : (_ctx.inputSpec.component === 'AdScheduleInput')
                                                                                                  ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 41 }, _ctx.inputSpec, {
                                                                                                      label: _ctx.label,
                                                                                                      "label-col": { span: _ctx.labelCol },
                                                                                                      "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                      name: _ctx.prop,
                                                                                                      help: _ctx.inputSpec.help,
                                                                                                      color: _ctx.inputSpec.color
                                                                                                    }), {
                                                                                                      default: _withCtx(() => [
                                                                                                        _createVNode(_component_ad_schedule_input, _mergeProps(_ctx.inputSpec, {
                                                                                                          value: _ctx.value,
                                                                                                          "onUpdate:value": _cache[67] || (_cache[67] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                          disabled: _ctx.disabled,
                                                                                                          onSelect: _ctx.inputSpec.select,
                                                                                                          onKeyup: _cache[68] || (_cache[68] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                                        }), null, 16, ["value", "disabled", "onSelect"])
                                                                                                      ]),
                                                                                                      _: 1
                                                                                                    }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                                  : (_ctx.inputSpec.component === 'AdRequirementInput')
                                                                                                    ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 42 }, _ctx.inputSpec, {
                                                                                                        label: _ctx.label,
                                                                                                        "label-col": { span: _ctx.labelCol },
                                                                                                        "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                        name: _ctx.prop,
                                                                                                        help: _ctx.inputSpec.help,
                                                                                                        color: _ctx.inputSpec.color
                                                                                                      }), {
                                                                                                        default: _withCtx(() => [
                                                                                                          _createVNode(_component_ad_requirement_input, _mergeProps(_ctx.inputSpec, {
                                                                                                            value: _ctx.value,
                                                                                                            "onUpdate:value": _cache[69] || (_cache[69] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                            disabled: _ctx.disabled,
                                                                                                            onSelect: _ctx.inputSpec.select,
                                                                                                            onKeyup: _cache[70] || (_cache[70] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"]))
                                                                                                          }), null, 16, ["value", "disabled", "onSelect"])
                                                                                                        ]),
                                                                                                        _: 1
                                                                                                      }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                                    : (_ctx.inputSpec.component === 'CrmReplacementInput')
                                                                                                      ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 43 }, _ctx.inputSpec, {
                                                                                                          label: _ctx.label,
                                                                                                          "label-col": { span: _ctx.labelCol },
                                                                                                          "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                          name: _ctx.prop,
                                                                                                          help: _ctx.inputSpec.help,
                                                                                                          color: _ctx.inputSpec.color
                                                                                                        }), {
                                                                                                          default: _withCtx(() => [
                                                                                                            _createVNode(_component_crm_replacement_input, _mergeProps(_ctx.inputSpec, {
                                                                                                              value: _ctx.value,
                                                                                                              "onUpdate:value": _cache[71] || (_cache[71] = ($event: any) => ((_ctx.value) = $event))
                                                                                                            }), null, 16, ["value"])
                                                                                                          ]),
                                                                                                          _: 1
                                                                                                        }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                                      : (_ctx.inputSpec.component === 'CrmBizmButtonInput')
                                                                                                        ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 44 }, _ctx.inputSpec, {
                                                                                                            label: _ctx.label,
                                                                                                            "label-col": { span: _ctx.labelCol },
                                                                                                            "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                            name: _ctx.prop,
                                                                                                            help: _ctx.inputSpec.help,
                                                                                                            color: _ctx.inputSpec.color
                                                                                                          }), {
                                                                                                            default: _withCtx(() => [
                                                                                                              _createVNode(_component_crm_bizm_button_input, _mergeProps(_ctx.inputSpec, {
                                                                                                                value: _ctx.value,
                                                                                                                "onUpdate:value": _cache[72] || (_cache[72] = ($event: any) => ((_ctx.value) = $event))
                                                                                                              }), null, 16, ["value"])
                                                                                                            ]),
                                                                                                            _: 1
                                                                                                          }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color"]))
                                                                                                        : (_ctx.inputSpec.component === 'InputCompleteInput')
                                                                                                          ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 45 }, _ctx.inputSpec, {
                                                                                                              label: _ctx.label,
                                                                                                              "label-col": { span: _ctx.labelCol },
                                                                                                              "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                              name: _ctx.prop,
                                                                                                              help: _ctx.inputSpec.help,
                                                                                                              color: _ctx.inputSpec.color,
                                                                                                              hidden: _ctx.hidden
                                                                                                            }), {
                                                                                                              default: _withCtx(() => [
                                                                                                                _createVNode(_component_input_complete_input, _mergeProps(_ctx.inputSpec, {
                                                                                                                  value: _ctx.value,
                                                                                                                  "onUpdate:value": _cache[73] || (_cache[73] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                                  onChange: _ctx.inputSpec.input,
                                                                                                                  disabled: _ctx.disabled
                                                                                                                }), null, 16, ["value", "onChange", "disabled"])
                                                                                                              ]),
                                                                                                              _: 1
                                                                                                            }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                                                          : (_ctx.inputSpec.type === 'textarea')
                                                                                                            ? (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 46 }, _ctx.inputSpec, {
                                                                                                                label: _ctx.label,
                                                                                                                "label-col": { span: _ctx.labelCol },
                                                                                                                "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                                name: _ctx.prop,
                                                                                                                help: _ctx.inputSpec.help,
                                                                                                                color: _ctx.inputSpec.color,
                                                                                                                hidden: _ctx.hidden
                                                                                                              }), {
                                                                                                                default: _withCtx(() => [
                                                                                                                  _createVNode(_component_a_textarea, _mergeProps(_ctx.inputSpec, {
                                                                                                                    value: _ctx.value,
                                                                                                                    "onUpdate:value": _cache[74] || (_cache[74] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                                    disabled: _ctx.disabled,
                                                                                                                    onKeyup: _cache[75] || (_cache[75] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"])),
                                                                                                                    style: {
                        width: _ctx.inputSpec.width || '550px',
                        height: _ctx.inputSpec.height || 'auto',
                    }
                                                                                                                  }), null, 16, ["value", "disabled", "style"])
                                                                                                                ]),
                                                                                                                _: 1
                                                                                                              }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
                                                                                                            : (_openBlock(), _createBlock(_component_form_item, _mergeProps({ key: 47 }, _ctx.inputSpec, {
                                                                                                                label: _ctx.label,
                                                                                                                "label-col": { span: _ctx.labelCol },
                                                                                                                "wrapper-col": { span: _ctx.wrapperCol },
                                                                                                                name: _ctx.prop,
                                                                                                                help: _ctx.inputSpec.help,
                                                                                                                color: _ctx.inputSpec.color,
                                                                                                                hidden: _ctx.hidden
                                                                                                              }), {
                                                                                                                default: _withCtx(() => [
                                                                                                                  _createVNode(_component_a_input, _mergeProps(_ctx.inputSpec, {
                                                                                                                    value: _ctx.value,
                                                                                                                    "onUpdate:value": _cache[77] || (_cache[77] = ($event: any) => ((_ctx.value) = $event)),
                                                                                                                    disabled: _ctx.disabled,
                                                                                                                    onChange: _ctx.inputSpec.input,
                                                                                                                    onKeyup: _cache[78] || (_cache[78] = _withKeys(($event: any) => (_ctx.$emit('press-enter')), ["enter"])),
                                                                                                                    style: { width: _ctx.inputSpec.width || '20em' }
                                                                                                                  }), _createSlots({ _: 2 }, [
                                                                                                                    (_ctx.inputSpec.copy)
                                                                                                                      ? {
                                                                                                                          name: "suffix",
                                                                                                                          fn: _withCtx(() => [
                                                                                                                            _createVNode(_component_a_tooltip, { title: "복사" }, {
                                                                                                                              default: _withCtx(() => [
                                                                                                                                _createVNode(_component_a_button, { shape: "circle" }, {
                                                                                                                                  default: _withCtx(() => [
                                                                                                                                    _createVNode(_component_copy_outlined, {
                                                                                                                                      onClick: _cache[76] || (_cache[76] = ($event: any) => (_ctx.copy(_ctx.value)))
                                                                                                                                    })
                                                                                                                                  ]),
                                                                                                                                  _: 1
                                                                                                                                })
                                                                                                                              ]),
                                                                                                                              _: 1
                                                                                                                            })
                                                                                                                          ]),
                                                                                                                          key: "0"
                                                                                                                        }
                                                                                                                      : undefined
                                                                                                                  ]), 1040, ["value", "disabled", "onChange", "style"])
                                                                                                                ]),
                                                                                                                _: 1
                                                                                                              }, 16, ["label", "label-col", "wrapper-col", "name", "help", "color", "hidden"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}