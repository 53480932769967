import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, {
    columns: _ctx.columns,
    "data-source": _ctx.tableData,
    loading: _ctx.loadingDataSource,
    scroll: _ctx.scroll,
    size: "small",
    pagination: false,
    style: {"padding":"20px 0 20px 0"},
    bordered: ""
  }, {
    title: _withCtx(() => [
      _createElementVNode("b", null, _toDisplayString(_ctx.title), 1)
    ]),
    tags: _withCtx((tags) => [
      _createElementVNode("span", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tags, (tag) => {
          return (_openBlock(), _createBlock(_component_a_tag, {
            key: tag,
            color: "blue"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(tag), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["columns", "data-source", "loading", "scroll"]))
}