import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    closable: false,
    footer: null,
    centered: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_result, {
        status: "error",
        title: _ctx.title,
        "sub-title": _ctx.errorMessage
      }, {
        extra: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.close
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 닫기 ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["title", "sub-title"])
    ]),
    _: 1
  }, 8, ["visible"]))
}