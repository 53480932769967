import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "포인트샵 상품",
    uri: "/api/admin/point-shop/products/category",
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "create-rules": _ctx.createRules,
    "update-rules": _ctx.updateRules,
    columns: _ctx.columns,
    "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
    "load-on-mount": ""
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus(true, uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 활성화 ")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateStatus(false, uri, search)
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 비활성화 ")
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }, 8, ["create-spec", "update-spec", "create-rules", "update-rules", "columns", "row-selection"]))
}