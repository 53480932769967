import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dfecb21"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_ad_preview = _resolveComponent("ad-preview")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "scoped-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, { span: 24 }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.header), 1)
        ]),
        _: 1
      }),
      (_ctx.instanceList?.length > 0)
        ? (_openBlock(), _createBlock(_component_a_col, {
            key: 0,
            span: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_draggable, {
                class: "list-group",
                itemKey: "id",
                list: _ctx.instanceList || [],
                group: { name: 'instances' }
              }, {
                item: _withCtx(({ element, index }) => [
                  _createVNode(_component_ad_preview, {
                    class: "preview",
                    index: index,
                    deletable: true,
                    instance: element,
                    onRemove: _ctx.remove
                  }, null, 8, ["index", "instance", "onRemove"])
                ]),
                _: 1
              }, 8, ["list"])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_a_col, {
            key: 1,
            span: 24
          }, {
            default: _withCtx(() => [
              _createTextVNode("데이터 없음")
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}