<template>
    <a-table
        :columns="columns"
        :data-source="tableData"
        :loading="loadingDataSource"
        :scroll="scroll"
        size="small"
        :pagination="false"
        style="padding: 20px 0 20px 0"
        bordered
    >
        <template #title>
            <b>{{ title }}</b>
        </template>
        <template #tags="tags">
            <span>
                <a-tag v-for="tag in tags" :key="tag" color="blue">{{
                    tag
                }}</a-tag>
            </span>
        </template>
    </a-table>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'NestedResourceTable',

    props: {
        columns: {
            type: Array,
            required: true,
        },
        uri: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            required: true,
        },
        scroll: {
            type: Object,
            default: () => ({ y: 400 }),
        },
    },

    setup() {
        const loadingDataSource = ref(false)
        const tableData = ref([])

        return { loadingDataSource, tableData }
    },

    methods: {
        async fetchData() {
            this.loadingDataSource = true
            const result = await adminApi<ApiResponse<Record<string, any>>>(
                `${this.uri}?${stringify(this.params, { indices: false })}`
            )

            if (!result.success) {
                console.error(result.errorMessage)
            }
            this.tableData = result?.result?.content || result?.result
            this.loadingDataSource = false
        },
    },

    mounted() {
        this.fetchData().then()
    },
})
</script>
