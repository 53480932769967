<template>
    <span>
        <AutoCompleteInput
            :label="label"
            uri="/api/food/ingredient"
            :width="width"
            :value="value"
            @update:value="change"
            @select="select"
            :mode="mode"
            :disabled="disabled"
        />
    </span>
</template>

<script setup lang="ts">
import AutoCompleteInput from '@/components/input/AutoCompleteInput.vue'

defineProps({
    value: {
        type: [Number, String],
    },
    label: {
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: '100%',
    },
    mode: {
        type: String,
        default: 'default',
    },
})

const emit = defineEmits(['update:value', 'select'])

const change = (value: any) => {
    emit('update:value', value)
}

const select = (value: any) => {
    emit('select', value)
}
</script>
