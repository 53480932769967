<template>
    <a-form-item v-bind="$props">
        <template #extra>
            <div v-if="computedDescription == true" style="padding-bottom: 8px">
                <span v-if="label === '태그'">
                    <b>광고</b> 하고 있거나, <b>이름</b>에 붙어 있는 내용 위주로
                    <b>최대 3개</b>까지 선택해주세요
                </span>
                <span v-if="label === '제품명'">
                    <a
                        :href="
                            (value &&
                                `https://www.tfood.go.kr/tfweb/nhq/nhq201List.do?page=1&foodHistraceNum=&radio=pdtNm&searchTxt=${value}`) ||
                            'https://www.tfood.go.kr/tfweb/nhq/nhq201Search.do'
                        "
                        target="_blank"
                    >
                        제품명으로 식품 조회하기
                    </a>
                </span>
                <span v-if="label === '식품이력추적번호'">
                    <a
                        :href="
                            (value &&
                                `https://www.tfood.go.kr/tfweb/nhq/nhq201ListData.do?page=1&regNum=${value}&foodHistraceNum=&radio=pdtNm`) ||
                            'https://www.tfood.go.kr/tfweb/nhq/nhq201Search.do'
                        "
                        target="_blank"
                    >
                        등록번호로 식품 조회하기
                    </a>
                    <br />
                    <b>국내 제품인 경우</b> 위의 사이트에서 검색한 후, 주소
                    중간에 있는 <b>“regNum=”</b> 뒤의 숫자를 써주세요<br />
                    검색이 안된다면, GMO/색소/향료까지 따로 입력하지 않고
                    넘어가시면 됩니다.<br />
                    <b>해외 제품인 경우에도,</b> 아무 입력하지 않고
                    넘어가주세요.<br />
                </span>
                <span v-if="label === '섭취방법'">
                    어떻게” 먹는지에 대한 내용을 표현 그대로 옮겨 주시면
                    됩니다<br />
                    예_ 2일에 한번/ 음료와 섞어서/ 480ml 이상의 물 또는 주스와
                    잘 섞어서 /서서히 늘려서
                </span>
                <span v-if="label === '섭취노트1'">
                    “주의사항”에 대한 내용을 남겨주세요:)<br />
                    예_만 12세 이상만 섭취 가능해요/2캡슐 이상 드시는 경우,
                    전문가와 상담하세요/필요에 따라 섭취량을 늘릴 수 있어요
                </span>
                <span v-if="label === '제형'">
                    영양성분표에서 <b>[1일/1회 섭취량]</b> 또는
                    <b>[Serving Size]</b>를 입력해주세요. <br />
                    알약 크기를 물어보는 제형: 캡슐, 정, 개, 구미, 환, 너겟, 알,
                    조각, 편, 팩
                </span>
                <span v-if="label === '원본유형'">
                    수정이 필요할때만 사용해주세요(”아이허브”에서 가져온 경우,
                    I로 입력되었는지 확인)
                </span>
                <span v-if="label === '원본 ID'">
                    <b>“아이허브”</b>에서 가져온 경우,
                    <b>주소의 맨 끝 “/” 뒤에 써있는 숫자</b>를 입력해주세요
                </span>
                <span v-if="name === 'recommendTime1'">
                    제품에서 특별히 추천하고 있는 시간이 없다면, “추천 시간
                    없음”을 선택해주세요<br />
                    <b
                        >아침, 점심, 저녁에는 식전/식후를 써주시고, 나머지는
                        안써주셔도 됩니다.</b
                    >
                </span>
            </div>
            <div v-else :style="{ paddingBottom: '8px', color: color }" />
        </template>
        <slot />
        <a
            v-if="
                typeof value === 'string' &&
                value.match(/^http:\/\/|^https:\/\//)
            "
            :href="sanitizeUrl(value)"
            target="_blank"
            style="padding-left: 10px"
        >
            링크
            <link-outlined />
        </a>
    </a-form-item>
</template>

<script setup>
import { computed } from 'vue'
import { sanitizeUrl } from '@braintree/sanitize-url'
import { LinkOutlined } from '@ant-design/icons-vue'

const props = defineProps({
    label: {
        type: String,
    },
    help: {
        type: [Boolean, String],
    },
    description: {
        type: [Boolean, String, Function],
    },
    color: {
        type: String,
    },
    value: {
        type: [String, Number, Boolean, Array, Object],
    },
    name: {
        type: String,
    },
})

const computedDescription = computed(() => {
    if (typeof props.description === 'function') {
        return props.description()
    }
    return props.description
})
</script>
