import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "댓글",
    uri: "/api/v1/comments",
    "filter-spec": _ctx.filterSpec,
    "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
    scroll: { x: true },
    columns: _ctx.columns,
    exportable: "",
    "load-on-mount": ""
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_a_button, {
        style: {"color":"black"},
        loading: _ctx.loading,
        disabled: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode("댓글 상태 변경")
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_a_button, {
        onClick: (event) => _ctx.updateStatus(event, uri, search),
        value: "NORMAL"
      }, {
        default: _withCtx(() => [
          _createTextVNode("노멀")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: (event) => _ctx.updateStatus(event, uri, search),
        value: "ADMIN_BLIND"
      }, {
        default: _withCtx(() => [
          _createTextVNode("블라인드")
        ]),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: (event) => _ctx.updateStatus(event, uri, search),
        value: "ADMIN_DELETED"
      }, {
        default: _withCtx(() => [
          _createTextVNode("운영자 삭제")
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }, 8, ["filter-spec", "row-selection", "columns"]))
}