<template>
    <div style="background-color: #f9fefe; padding: 13px; width: auto">
        <a-row style="padding-bottom: 10px">
            <a-form ref="formRule" :model="form" :rules="rules" layout="inline">
                <a-form-item name="content">
                    <a-input
                        name="content"
                        v-model:value="form.content"
                        :disabled="disabled"
                    />
                </a-form-item>
                <a-form-item name="value">
                    <a-input-number
                        v-model:value="form.value"
                        :disabled="disabled"
                    />
                </a-form-item>
                <a-form-item name="isDefault" label="정답">
                    <boolean-input v-model:value="form.answer" toggle />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="add" :disabled="disabled">
                        선택지 추가
                    </a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-row>
            <draggable :list="valueRef" item-key="value">
                <template #item="{ element, index }">
                    <a-form
                        :model="element"
                        :rules="rules"
                        layout="inline"
                        style="border-color: #6c2bf2; padding-bottom: 10px"
                    >
                        <a-form-item>
                            <swap-outlined :rotate="90" />
                        </a-form-item>
                        <a-form-item name="content">
                            <a-input
                                name="content"
                                v-model:value="element.content"
                                :disabled="disabled"
                            />
                        </a-form-item>
                        <a-form-item name="value">
                            <a-input-number
                                v-model:value="element.value"
                                :disabled="disabled"
                            />
                        </a-form-item>
                        <a-form-item name="isDefault" label="정답">
                            <boolean-input
                                v-model:value="element.answer"
                                toggle
                            />
                        </a-form-item>
                        <a-form-item>
                            <a-button
                                type="danger"
                                @click="() => pop(index)"
                                :disabled="disabled"
                            >
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                    </a-form>
                </template>
            </draggable>
        </a-row>
    </div>
</template>

<script setup lang="ts">
import { ref, toRef } from 'vue'
import BooleanInput from '@/components/input/BooleanInput.vue'
import { aboveOrEqualToZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { SwapOutlined, DeleteOutlined } from '@ant-design/icons-vue'

type SurveyOption = {
    id?: number
    content: string | null
    value: number
    answer: boolean
}

const props = defineProps({
    rules: {
        type: Object,
        default: () => ({
            content: [required],
            value: [required, aboveOrEqualToZero],
        }),
    },
    productId: {
        type: Number,
    },
    value: {
        type: Array,
        default: () => [],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const form = ref<SurveyOption>({
    content: null,
    value: 0,
    answer: false,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: SurveyOption) => {
    valueRef.value.push(value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
}

const add = () => {
    return formRule.value.validate().then(() => {
        if (props.value != null) {
            push(form.value)
            form.value = {
                content: null,
                value: 0,
                answer: false,
            }
        }
    })
}
</script>
