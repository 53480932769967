<template>
    <ResourceTable
        title="상품 댓글"
        uri="/api/v1/product/reviews"
        :filter-spec="filterSpec"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        :scroll="{ x: 2000, y: 650 }"
        :columns="columns"
        load-on-mount
        exportable
    >
        <template #actions="{ uri, search }">
            <a-button style="color: black" :loading="loading" disabled
                >리뷰 상태 변경</a-button
            >
            <a-button @click="() => updateStatus('NORMAL', uri, search)"
                >노멀</a-button
            >
            <a-button @click="() => updateStatus('ADMIN_BLIND', uri, search)"
                >블라인드</a-button
            >
            <a-button @click="() => updateStatus('ADMIN_DELETED', uri, search)"
                >운영자 삭제</a-button
            >
            <a-button style="color: black" :loading="loading" disabled
                >베스트 지정</a-button
            >
            <a-button @click="() => updateBest('true', uri, search)"
                >Best</a-button
            >
            <a-button @click="() => updateBest('false', uri, search)"
                >Unbest</a-button
            >
        </template>
        <template #detail="{ record, search, fetchResource }">
            <a-descriptions title="리뷰 상세" bordered="true" layout="vertical">
                <a-descriptions-item label="리뷰 ID">{{
                    record.id
                }}</a-descriptions-item>
                <a-descriptions-item label="리뷰 점수">{{
                    record.score
                }}</a-descriptions-item>
                <a-descriptions-item label="사용자 ID">{{
                    record.userId
                }}</a-descriptions-item>
                <a-descriptions-item label="베스트 여부">{{
                    record.isBest
                }}</a-descriptions-item>
                <a-descriptions-item label="베스트 점수" :span="2">
                    <div style="display: flex; align-items: center">
                        <a-input-number
                            style="margin-right: 6px"
                            v-bind="rankScore"
                            v-model:value="record.rankScore"
                            @change="handleRankScore"
                        />
                        <UpdatePopConfirm
                            title="수정하시겠습니까?"
                            :uri="`/api/v1/product/reviews/${record.id}/rank`"
                            :payload="rankScorePayload"
                            @after-blind="
                                () => {
                                    search()
                                    fetchResource()
                                }
                            "
                        />
                    </div>
                </a-descriptions-item>
                <a-descriptions-item label="댓글" :span="3">{{
                    record.content
                }}</a-descriptions-item>
                <a-descriptions-item label="이미지" :span="3">
                    <span v-if="record?.imageList?.length === 0">
                        이미지 없음
                    </span>
                    <div style="display: flex; align-items: flex-end">
                        <a-image-preview-group>
                            <span
                                v-for="(image, index) in record.imageList"
                                :key="index"
                                style="display: inline-grid; margin-right: 6px"
                            >
                                <a-image
                                    style="margin-bottom: 6px"
                                    :width="128"
                                    :src="image.thumbnailImageUrl"
                                />
                                <BlindPopconfirm
                                    :title="`이미지를 ${
                                        (image.blinded && '노출') || '블라인드'
                                    } 하시겠습니까?`"
                                    :uri="`/api/v1/product/reviews/${record.id}/images/blind?imageUrl=${image.imageUrl}`"
                                    :blinded="image.blinded"
                                    @after-blind="
                                        () => {
                                            search()
                                            fetchResource()
                                        }
                                    "
                                />
                            </span>
                        </a-image-preview-group>
                    </div>
                </a-descriptions-item>
                <a-descriptions-item label="상품명">{{
                    record.shoppingProductName
                }}</a-descriptions-item>
                <a-descriptions-item label="상품 ID">{{
                    record.shoppingProductId
                }}</a-descriptions-item>
                <a-descriptions-item label="상품 옵션 ID">{{
                    record.shoppingProductOptionId
                }}</a-descriptions-item>
                <a-descriptions-item label="상품 이미지" :span="3">
                    <a-image-preview-group>
                        <a-image
                            style="margin-right: 6px"
                            :width="256"
                            :src="record.shoppingProductThumbnailUrl1"
                        />
                    </a-image-preview-group>
                </a-descriptions-item>
                <a-descriptions-item label="상태">{{
                    record.status
                }}</a-descriptions-item>
                <a-descriptions-item label="블라인드 처리자" :span="2">{{
                    record.blindedBy
                }}</a-descriptions-item>
                <a-descriptions-item label="주문 수">{{
                    record.orderCount
                }}</a-descriptions-item>
                <a-descriptions-item label="주문 번호" :span="2">{{
                    record.orderItemId
                }}</a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import { formatBoolean, formatLocalDateTime } from '@/util/formmater'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, Ref, ref } from 'vue'
import BlindPopconfirm from '@/components/BlindPopconfirm.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { isEmpty } from 'lodash'
import UpdatePopConfirm from '@/components/UpdatePopConfirm.vue'

export default defineComponent({
    name: 'ProductReview',
    mixins: [TableSupport],
    components: {
        UpdatePopConfirm,
        ResourceTable,
        BlindPopconfirm,
    },
    setup() {
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const selectedImageUrl = ref('')
        const showImage = ref(false)
        const loading = ref(false)

        return {
            selectedRowKeys,
            selectedImageUrl,
            showImage,
            loading,
        }
    },

    methods: {
        handleRankScore(value: any) {
            this.rankScore = value
            this.rankScorePayload = {
                rank: value,
            }
        },
        onClickImage(event: any) {
            this.selectedImageUrl = event.target.src
            this.showImage = true
        },
        onSelectChange(selectedRowKeys: Array<number>) {
            this.selectedRowKeys = selectedRowKeys
        },
        updateStatus(status: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                reviewStatus: status,
            }
            const queryParams = stringify(params, { indices: false })
            this.updateReview(queryParams, uri, search)
        },
        updateBest(best: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                isBest: best,
            }
            const queryParams = stringify(params, { indices: false })
            this.updateReview(queryParams, uri + '/best', search)
        },
        async updateReview(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                    }, 1000)
                }
            }
        },
        async deleteImage(imageId: number) {
            await adminApi<ApiResponse<void>>(
                `/api/v1/reviews/image/${imageId}`,
                {
                    method: 'DELETE',
                }
            )
        },
    },

    data() {
        const rankScore = ref(0)
        const rankScorePayload = ref({})
        return {
            rankScore,
            rankScorePayload,
            filterSpec: {
                id: { label: 'ID', value: null, component: 'AInput' },
                productId: {
                    label: '제품 ID',
                    value: null,
                    component: 'AInputNumber',
                },
                userId: {
                    label: '사용자 ID',
                    value: [],
                    component: 'AutoCompleteInput',
                    uri: '/api/user/id',
                    mode: 'multiple',
                },
                reportedCount: {
                    label: '신고 수',
                    value: null,
                    component: 'AInputNumber',
                },
                status: {
                    label: '상태',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'review-status',
                    input: () => {
                        this.reload()
                    },
                },
                isBest: {
                    label: '베스트여부',
                    value: null,
                    component: 'BooleanInput',
                    trueText: '베스트',
                    falseText: '일반',
                },
                hasPhoto: {
                    label: '사진첨부',
                    value: null,
                    component: 'BooleanInput',
                    trueText: '사진첨부',
                    falseText: '사진미첨부',
                },
            },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    sorter: true,
                },
                {
                    title: '사용자 ID',
                    dataIndex: 'userId',
                },
                {
                    title: '베스트 리뷰',
                    dataIndex: 'isBest',
                    customRender: formatBoolean,
                },
                {
                    title: '베스트 Rank',
                    dataIndex: 'rankScore',
                },
                {
                    title: '리뷰 별점',
                    dataIndex: 'score',
                },
                {
                    title: '리뷰',
                    customRender: (value: any) => {
                        const obj = value?.record
                        return obj.content
                    },
                    type: 'text-ellipsis',
                    dataIndex: 'content',
                    width: '256px',
                },
                {
                    title: '상품명',
                    dataIndex: 'shoppingProductName',
                },
                {
                    title: '상품 ID',
                    customRender: (value: any) => {
                        const obj = value?.record
                        const result = isEmpty(obj.shoppingProductOptionId)
                            ? obj.shoppingProductId
                            : `${obj.shoppingProductId} (option:${obj.shoppingProductOptionId})`
                        return result
                    },
                    dataIndex: 'shoppingProductOptionId',
                },
                {
                    title: '상품 이미지',
                    dataIndex: 'shoppingProductThumbnailUrl1',
                    width: 128,
                    type: 'img',
                },
                {
                    title: '상태',
                    dataIndex: 'status',
                },
                {
                    title: '블라인드 처리자',
                    dataIndex: 'blindedBy',
                    customRender: (value: any, record: any) => {
                        const status = ''
                        if (value.record.blindedBy === 0) {
                            return status + '(시스템 필터링)'
                        } else if (value.record.blindedBy !== null) {
                            // make the code short
                            return (
                                status +
                                '관리자 (' +
                                value.record.blindedBy +
                                ')'
                            )
                        } else {
                            return status
                        }
                    },
                },
                {
                    title: '주문번호',
                    dataIndex: 'orderItemId',
                },
                {
                    title: '주문수',
                    dataIndex: 'orderCount',
                },
                {
                    title: '리뷰 포인트',
                    dataIndex: 'pointAmount',
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    sorter: true,
                },
                {
                    title: '수정일시',
                    dataIndex: 'updateTime',
                    customRender: formatLocalDateTime,
                },
                {
                    title: '이미지 포함',
                    fixed: 'right',
                    align: 'center',
                    width: 96,
                    type: 'hasImageSize',
                },
                {
                    title: '상세',
                    dataIndex: 'detail',
                    width: 96,
                    fixed: 'right',
                    align: 'center',
                    type: 'detail',
                    scopedSlots: { customRender: 'detail' },
                },
            ],
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'review-status',
            'review-step',
            'reviewer-type',
        ])
    },
})
</script>
