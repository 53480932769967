<template style="display: flex; align-items: center">
    <div>
        <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :multiple="true"
            :action="uploadUrl"
            @change="handleChange"
            :before-upload="beforeUpload"
            with-credentials
            style="width: unset"
        >
            <img
                v-if="
                    value && !value.endsWith('.json') && !value.endsWith('.csv')
                "
                :src="value"
                alt="avatar"
                style="max-width: 100px; max-height: 100px"
            />
            <Vue3Lottie
                v-else-if="value && value.endsWith('.json')"
                :animationLink="value"
                :width="100"
                :height="100"
            />
            <div v-else>
                <loading-outlined v-if="loading" />
                <plus-outlined v-else />
                <div class="ant-upload-text">Upload</div>
            </div>
        </a-upload>
        <span>
            <label>파일 주소: </label>
            <a-input
                :value="value"
                @change="handleInput"
                style="width: 200px"
            />
        </span>
    </div>
</template>

<style>
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>

<script setup lang="ts">
import { baseUrl } from '@/fetchTemplate'
import { computed, ref } from 'vue'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { fileToWebp } from '@/util/image'

const props = defineProps({
    value: {
        type: String,
        default: '',
    },
    path: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})
const loading = ref(false)

const uploadUrl = computed(() => {
    return `${baseUrl}/api/file?path=${props.path}`
})

const emits = defineEmits(['update:value'])

const handleInput = (event: any) => {
    emits('update:value', event.target.value)
}

const handleSelect = (value: any) => {
    emits('update:value', value)
}

const handleChange = async (info: any) => {
    if (info.file.status === 'uploading') {
        loading.value = true
        return
    }
    if (info.file.status === 'done') {
        const allDone = !info.fileList.some((f: any) => f.status !== 'done')
        if (allDone) {
            emits('update:value', info.file.response.result)
            loading.value = false
        }
    }
}

const beforeUpload = (file: any) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
        return fileToWebp(file)
    }
    return file
}
</script>
