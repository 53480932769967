import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_time_range_picker = _resolveComponent("a-time-range-picker")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRule",
    model: _ctx.form
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions, {
            bordered: true,
            column: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_descriptions_item, { label: "시작/종료" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_range_picker, {
                    "show-time": "",
                    "value-format": "YYYY-MM-DD HH:mm:ss",
                    minuteStep: 5,
                    secondStep: 60,
                    value: _ctx.dateRange,
                    onChange: _ctx.changeDateRange
                  }, null, 8, ["value", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "반복 선택" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createVNode(_component_a_checkbox, {
                              checked: _ctx.checkAll,
                              onChange: _ctx.changeCheckAll
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" 모두 선택 ")
                              ]),
                              _: 1
                            }, 8, ["checked", "onChange"])
                          ]),
                          _createElementVNode("div", null, [
                            _createVNode(_component_a_checkbox_group, {
                              value: _ctx.form.schedule.repeatDayOfWeeks,
                              options: _ctx.dayOfWeekOptions,
                              onChange: _ctx.changeCheck
                            }, null, 8, ["value", "options", "onChange"])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_divider, { style: {"margin":"12px 0px"} }),
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_time_range_picker, {
                            minuteStep: 5,
                            secondStep: 60,
                            value: _ctx.timeRange,
                            valueFormat: "HH:mm:ss",
                            onChange: _ctx.changeTimeRange
                          }, null, 8, ["value", "onChange"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}