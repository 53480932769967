import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_NutrientAnalyzeReport = _resolveComponent("NutrientAnalyzeReport")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_BulkActionModal = _resolveComponent("BulkActionModal")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_NotificationOutlined = _resolveComponent("NotificationOutlined")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, { title: "인기질문" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_list, {
          "item-layout": "horizontal",
          "data-source": _ctx.featureList,
          loading: _ctx.listLoading
        }, {
          renderItem: _withCtx(({ item }) => [
            _createVNode(_component_a_list_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_list_item_meta, {
                  description: item.questionContent
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(`ID ${item.id}. ${item.title}`), 1)
                  ]),
                  avatar: _withCtx(() => [
                    _createVNode(_component_a_avatar, {
                      src: item.ownerProfileImageUrl
                    }, null, 8, ["src"])
                  ]),
                  _: 2
                }, 1032, ["description"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["data-source", "loading"])
      ]),
      _: 1
    }),
    _createVNode(_component_ResourceTable, {
      title: "질문",
      uri: "/api/v1/questions",
      "filter-spec": _ctx.filterSpec,
      "row-selection": {
                selectedRowKeys: _ctx.selectedRowKeys,
                onChange: _ctx.onSelectChange,
            },
      scroll: { x: 2000 },
      columns: _ctx.columns,
      "update-spec": _ctx.updateSpec,
      "action-spec": {},
      "action-modal-title": "알림",
      "action-uri": "/api/v1/questions/reply",
      actionModalDisabled: _ctx.actionModalDisabled,
      "create-rules": _ctx.rules,
      "update-rules": _ctx.rules,
      exportable: "",
      "load-on-mount": "",
      onAfterSearch: _ctx.fetchFeatureList
    }, {
      updateModalHeader: _withCtx(({ form }) => [
        (form.userId && form.productIds)
          ? (_openBlock(), _createBlock(_component_NutrientAnalyzeReport, {
              key: 0,
              ref: "report",
              "user-id": form.userId,
              "product-ids": form.productIds
            }, null, 8, ["user-id", "product-ids"]))
          : _createCommentVNode("", true)
      ]),
      actionModalHeader: _withCtx(({ record }) => [
        _createVNode(_component_a_descriptions, { column: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_descriptions_item, { label: "ID" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(`${record.id}를 처리하시겠습니까?`), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "제목" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.title), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "내용" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.questionContent), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "답변" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.answerContentText), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      actions: _withCtx(({ uri, search }) => [
        _createVNode(_component_a_button, {
          style: {"color":"black"},
          loading: _ctx.loading,
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("질문 상태 변경")
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_a_button, {
          onClick: () => _ctx.updateStatus('NORMAL', uri, search)
        }, {
          default: _withCtx(() => [
            _createTextVNode("정상")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_button, {
          onClick: () => _ctx.updateStatus('ADMIN_BLIND', uri, search)
        }, {
          default: _withCtx(() => [
            _createTextVNode("블라인드")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_button, {
          onClick: () => _ctx.toggleFeatured(uri, search)
        }, {
          default: _withCtx(() => [
            _createTextVNode("인기 선정/비선정")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_button, {
          onClick: 
                        () =>
                            _ctx.updateResponseStatus(
                                'IN_PROGRESS',
                                `${uri}/response`,
                                search
                            )
                    
        }, {
          default: _withCtx(() => [
            _createTextVNode("처리중")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_button, {
          onClick: 
                        () =>
                            _ctx.updateResponseStatus(
                                'IGNORE',
                                `${uri}/response`,
                                search
                            )
                    
        }, {
          default: _withCtx(() => [
            _createTextVNode("무시")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_BulkActionModal, {
          onAfterSubmit: search,
          "resource-ids": _ctx.selectedRowKeys,
          uri: `${uri}/priority`,
          "input-spec": _ctx.prioritySpec,
          label: "우선순위 변경"
        }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec"])
      ]),
      detail: _withCtx(({ record }) => [
        _createVNode(_component_a_descriptions, {
          title: "질문 상세",
          bordered: "true",
          layout: "vertical",
          column: "1",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_descriptions_item, { label: "사용자 ID" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.userId), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "제목" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.title), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "사용자 정보" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.userAgeGenderCondition), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, {
              label: "내용",
              span: 3
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.questionContent), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "건강목표" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.userHigBroadSet), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "복용약물" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.userDrugSet), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "기저질병" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.userDiseaseSet), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "건강특수상태" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.userConditionSet), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "건강검진" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.userMcuDiagnosisSet), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "이미지" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_image_preview_group, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.images, (imageUrl, index) => {
                      return (_openBlock(), _createBlock(_component_a_image, {
                        key: index,
                        width: 200,
                        src: imageUrl
                      }, null, 8, ["src"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "질문 제품" }, {
              default: _withCtx(() => [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.targetProducts, (p) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: p.id
                    }, [
                      _createElementVNode("a", {
                        href: _ctx.getProductUrl(p.productId)
                      }, _toDisplayString(p.productName), 9, _hoisted_1)
                    ]))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_a_descriptions_item, { label: "섭취제품" }, {
              default: _withCtx(() => [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getParsed(
                                    record.userIntakeProductSnapshot
                                ), (p) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: p.id
                    }, [
                      _createElementVNode("a", {
                        href: _ctx.getProductUrl(p.productId)
                      }, _toDisplayString(p.productName), 9, _hoisted_2)
                    ]))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      customAction: _withCtx(({ record }) => [
        _createVNode(_component_a_button, {
          type: "primary",
          disabled: !record.userSnapshotId,
          loading: _ctx.mockLoading,
          onClick: ($event: any) => (_ctx.mockUser(record.userSnapshotId))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 복사 ")
          ]),
          _: 2
        }, 1032, ["disabled", "loading", "onClick"])
      ]),
      actionModalIcon: _withCtx(() => [
        _createVNode(_component_NotificationOutlined)
      ]),
      _: 1
    }, 8, ["filter-spec", "row-selection", "columns", "update-spec", "actionModalDisabled", "create-rules", "update-rules", "onAfterSearch"])
  ]))
}