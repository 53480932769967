<template>
    <ResourceTable
        title="활성 사용자 목록"
        uri="/api/user-management"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
    >
        <template #detail="{ record }">
            <a-descriptions
                title="팝업 상세"
                :bordered="true"
                layout="vertical"
                :column="1"
                size="small"
            >
                <a-descriptions-item label="이벤트명">
                    {{ record.name }}
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'User',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            actionName: { label: '액션 버튼 이름', value: null },
        }

        const updateSepc: FormSpecification = {
            id: {
                label: 'ID',
                value: null,
                readonly: true,
            },
            userName: {
                label: '닉네임',
                value: null,
                readonly: true,
            },
            role: {
                label: '역할',
                value: null,
                readonly: true,
            },
            birth: {
                label: '생년월일',
                value: null,
                readonly: true,
            },
            latestBuildVersion: {
                label: '최근 사용 빌드버전',
                value: null,
                readonly: true,
            },
            signupBuildVersion: {
                label: '가입 빌드번호',
                value: null,
                readonly: true,
            },
            socialLoginProvider: {
                label: '소셜로그인',
                value: null,
                readonly: true,
            },
            email: {
                label: '이메일',
                value: null,
                readonly: true,
            },
            emailForLibre: {
                label: '이메일(리브레용)',
                value: null,
                readonly: true,
            },
            isMale: {
                label: '성별',
                value: null,
                readonly: true,
            },
            ageRange: {
                label: '나이대(10세 단위)',
                value: null,
                readonly: true,
            },
            managementPurpose: {
                label: '관리 목적',
                value: null,
                readonly: true,
            },
            hasLibreDevice: {
                label: '가입시 리브레 장비 보유',
                value: null,
                readonly: true,
            },
            os: {
                label: 'OS',
                value: null,
                readonly: true,
            },
            creationTime: {
                label: '가입날짜',
                value: null,
                readonly: true,
            },
        }

        const filterSpec: FormSpecification = {
            id: {
                label: 'ID',
                value: null,
                component: InputComponents.AInputNumber,
            },
            userName: { label: '닉네임', value: null },
            encryptedId: { label: '암호화 ID', value: null },
        }

        return {
            selectedRowKeys: [],
            loading: false,
            createSpec,
            filterSpec,
            updateSpec: updateSepc,
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                },
                {
                    title: '닉네임',
                    dataIndex: 'userName',
                    width: 100,
                },
                {
                    title: 'role',
                    dataIndex: 'role',
                    width: 100,
                },
                {
                    title: '생년월일',
                    dataIndex: 'birth',
                    width: 100,
                },
                {
                    title: '빌드버전',
                    dataIndex: 'latestBuildVersion',
                    width: 100,
                },
                {
                    title: 'OS',
                    dataIndex: 'os',
                    width: 100,
                },
                {
                    title: '가입날짜',
                    dataIndex: 'creationTime',
                    width: 100,
                },
            ],
            rules: {
                startAt: [required],
                endAt: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['event-type'])
    },
})
</script>
