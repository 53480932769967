<template>
    <a-dropdown-button :id="id" class="profile-dropdown">
        {{ userStore.userName || '???' }}
        <template #overlay>
            <a-menu>
                <a-menu-item key="1" @click="showUser">정보</a-menu-item>
                <a-menu-item key="2" @click="logout">로그아웃</a-menu-item>
            </a-menu>
        </template>
        <template #icon>
            <a-avatar
                v-if="userStore.profileImageUrl"
                :src="userStore.profileImageUrl"
            />
            <user-outlined v-else />
        </template>
        <a-modal
            v-model:visible="userVisible"
            title="사용자 정보"
            width="900px"
        >
            <a-form
                class="user__modal"
                :model="userStore"
                :rules="{ bindUserId: [{ required: true }] }"
            >
                <span v-if="userStore.loginType == 'AWS_COGNITO'">
                    <a-form-item label="Cognito 사용자명">
                        <span style="font-weight: 600">{{
                            userStore.cognitoUsername
                        }}</span>
                    </a-form-item>
                    <a-form-item
                        name="bindUserId"
                        label="사용 유저 아이디"
                        extra="`서비스에서 사용되는 유저ID. 어드민 계정과 연동합니다.
                        유저의 아이디는 현재 ID가 갖는 권한을 부여받습니다.
                        연동하지 않을시 어드민에서 일부 서비스는 작동하지 않을수 있습니다.
                        (변경시에 이전 ID는 사용자로 강등됩니다)`"
                    >
                        <auto-complete-input
                            v-model:value="userStore.bindUserId"
                            :uri="`/api/user`"
                        >
                            <template #ext>
                                <a-button
                                    @click="updateCognitoUser"
                                    :loading="loading"
                                    :disabled="
                                        userStore.bindUserId == null ||
                                        userStore.bindUserId == ''
                                    "
                                    >유저 변경</a-button
                                >
                            </template>
                        </auto-complete-input>
                    </a-form-item>
                </span>
                <a-form-item label="프로필 이미지">
                    <image-input
                        v-model:value="userStore.profileImageUrl"
                        :path="`/admin/profile/${userStore.id}`"
                    />
                </a-form-item>
                <a-form-item label="닉네임">
                    <a-input v-model:value="userStore.userName" />
                </a-form-item>
                <a-form-item label="프로필 닉네임">
                    <a-input v-model:value="userStore.profileName" />
                </a-form-item>
                <a-form-item label="Role">
                    <a-input v-model:value="userStore.role" disabled />
                </a-form-item>
                <a-form-item
                    v-if="userStore.loginType == 'KAKAO'"
                    label="경력"
                    :colon="false"
                >
                    <a-row>
                        <a-form
                            layout="inline"
                            :model="careerForm"
                            :rules="rules"
                            @finish="
                                (values: any) =>
                                    addProfile(careerForm, 'CAREER', values)
                            "
                        >
                            <a-form-item label="내용" name="description">
                                <a-input
                                    v-model:value="careerForm.description"
                                    style="width: 300px !important"
                                />
                            </a-form-item>
                            <a-form-item label="취득일" name="registeredAt">
                                <a-date-picker
                                    show-time
                                    v-model:value="careerForm.registeredAt"
                                />
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit">
                                    <plus-outlined />
                                </a-button>
                            </a-form-item>
                        </a-form>
                    </a-row>
                    <br />
                    <a-row
                        v-for="(profile, index) in userStore.profiles.filter((it: any) => it.type == 'CAREER')"
                        :key="index"
                        style="margin-bottom: 10px"
                    >
                        <a-form layout="inline" :model="profile" :rules="rules">
                            <a-form-item label="내용" name="description">
                                <a-input
                                    v-model:value="profile.description"
                                    style="width: 300px !important"
                                />
                            </a-form-item>
                            <a-form-item label="취득일" name="registeredAt">
                                <a-date-picker
                                    v-model:value="profile.registeredAt"
                                    show-time
                                />
                            </a-form-item>
                            <a-form-item>
                                <a-button
                                    type="danger"
                                    @click="deleteProfile(profile)"
                                >
                                    <delete-outlined />
                                </a-button>
                            </a-form-item>
                        </a-form>
                    </a-row>
                </a-form-item>
                <a-divider />
                <a-form-item label="학력">
                    <a-row>
                        <a-form
                            layout="inline"
                            :model="degreeForm"
                            :rules="rules"
                            @finish="(values: any) => addProfile(degreeForm, 'DEGREE', values)"
                        >
                            <a-form-item label="내용" name="description">
                                <a-input
                                    v-model:value="degreeForm.description"
                                    style="width: 300px !important"
                                />
                            </a-form-item>
                            <a-form-item label="취득일" name="registeredAt">
                                <a-date-picker
                                    show-time
                                    v-model:value="degreeForm.registeredAt"
                                />
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit">
                                    <plus-outlined />
                                </a-button>
                            </a-form-item>
                        </a-form>
                    </a-row>
                    <br />
                    <a-row
                        v-for="profile in userStore.profiles.filter((it: any) => it.type == 'DEGREE')"
                        :key="profile.id"
                        style="margin-bottom: 10px"
                    >
                        <a-form layout="inline" :model="profile" :rules="rules">
                            <a-form-item label="내용" name="description">
                                <a-input
                                    v-model:value="profile.description"
                                    style="width: 300px !important"
                                />
                            </a-form-item>
                            <a-form-item label="취득일" name="registeredAt">
                                <a-date-picker
                                    v-model:value="profile.registeredAt"
                                    show-time
                                />
                            </a-form-item>
                            <a-form-item>
                                <a-button
                                    type="danger"
                                    @click="deleteProfile(profile)"
                                >
                                    <delete-outlined />
                                </a-button>
                            </a-form-item>
                        </a-form>
                    </a-row>
                </a-form-item>
                <a-form-item label="SNS 및 블로그 주소" name="url">
                    <a-input
                        v-model:value="userStore.contactLink"
                        style="width: 300px !important"
                    />
                </a-form-item>
            </a-form>
            <template #footer>
                <a-button
                    type="primary"
                    @click="updateUser"
                    :loading="loading"
                    :disabled="userStore.id == null"
                >
                    수정</a-button
                >
            </template>
        </a-modal>
    </a-dropdown-button>
</template>

<style lang="scss">
.profile-dropdown {
    button {
        height: 50px;
    }
    .ant-dropdown-trigger {
        width: 50px;
    }
}
</style>

<script lang="ts">
import { adminApi } from '@/fetchTemplate'
import { defineComponent, ref } from 'vue'
import { UserOutlined } from '@ant-design/icons-vue'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { required } from '@/util/input-validation'
import ImageInput from './input/ImageInput.vue'
import AutoCompleteInput from './input/AutoCompleteInput.vue'
import * as Kakao from '@/assets/kakao.js'
import { useUserStore } from '@/store/user'

export default defineComponent({
    name: 'UserProfile',
    components: {
        UserOutlined,
        PlusOutlined,
        DeleteOutlined,
        ImageInput,
        AutoCompleteInput,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },

    setup() {
        const userStore = useUserStore()
        const userVisible = ref(false)
        const loading = ref(false)
        const careerForm = ref({
            description: null,
            registeredAt: null,
            main: false,
            type: 'CAREER',
        })
        const degreeForm = ref({
            description: null,
            registeredAt: null,
            main: false,
            type: 'DEGREE',
        })
        const contactLinkForm = ref({
            id: null,
            url: null,
            type: null,
            main: true,
        })

        const addProfile = (value: any, type: string, values: any) => {
            if (values == undefined) return

            userStore.profiles?.push({ ...value, type })
            if (type === 'CAREER') {
                careerForm.value = {
                    description: null,
                    registeredAt: null,
                    main: false,
                    type: 'CAREER',
                }
            } else {
                degreeForm.value = {
                    description: null,
                    registeredAt: null,
                    main: false,
                    type: 'DEGREE',
                }
            }
        }
        const setContactLink = () => {
            userStore.contactLink = contactLinkForm.value
        }

        const deleteProfile = (profile: any) => {
            userStore.profiles.splice(userStore.profiles.indexOf(profile), 1)
        }
        const rules = {
            description: [required],
            registeredAt: [required],
        }
        const contactLinkRules = {
            type: [required],
            url: [required],
        }
        const toggleLoading = () => {
            loading.value = !loading.value
        }

        return {
            userVisible,
            careerForm,
            degreeForm,
            contactLinkForm,
            addProfile,
            deleteProfile,
            rules,
            contactLinkRules,
            toggleLoading,
            loading,
            setContactLink,
            userStore,
        }
    },

    methods: {
        async logout() {
            try {
                if (this.userStore.loginType === 'KAKAO') Kakao.Auth.logout()
                await adminApi(`/api/auth/logout`, {
                    method: 'POST',
                })
            } catch (e) {
                console.error(e)
            } finally {
                await this.userStore.$reset()
                this.$router.push('/login')
            }
        },

        showUser() {
            this.userVisible = !this.userVisible
            if (this.userVisible) {
                this.userStore.fetchUser()
            }
        },

        async updateUser() {
            this.toggleLoading()
            await adminApi(`/api/user`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.userStore.$state),
            })
            this.userStore.fetchUser()
            this.toggleLoading()
        },

        async updateCognitoUser() {
            this.toggleLoading()
            await adminApi(`/api/user/cognito`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.userStore.$state),
            })
            await this.userStore.fetchUser()
            this.toggleLoading()
        },
    },
})
</script>
