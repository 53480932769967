import { SelectOption, useEnumTypeStore } from '@/store/enumType'
import Moment from 'moment'
import { FormatOptions, numToKorean } from 'num-to-korean'

type CellProps = {
    index?: number
    text?: any
    record?: Record<any, any>
}

export const formatLocalDateTime = (value: CellProps) =>
    (value.text && Moment(value.text).format('YYYY-MM-DD HH:mm:ss')) || ''

export const formatLocalDate = (value: CellProps) =>
    (value.text && Moment(value.text).format('YYYY-MM-DD')) || ''

export const formatBoolean = (row: Record<any, any>) => {
    const value = row.value
    if (value === false || value === 'false') return 'X'
    if (value === true || value === 'true') return 'O'
    return ''
}

export const formatEnum = (typeName: string) => (value: CellProps) => {
    const enumTypeStore = useEnumTypeStore()
    return enumTypeStore
        .getEnumType(typeName)
        ?.filter((it: SelectOption) => it.value == value.text)[0]?.label
}

export const formatEnumList = (typeName: string) => (value: CellProps) => {
    const enumTypeStore = useEnumTypeStore()
    return value.text
        .map(
            (text: string) =>
                enumTypeStore
                    .getEnumType(typeName)
                    ?.filter((it: SelectOption) => it.value == text)[0]?.label
        )
        .join(', ')
}

export const formatLocaleNumber = (value: CellProps) => {
    const n = Number(value.text)
    if (isNaN(n)) {
        return '0'
    }

    if (value.text == 9999999999999) {
        return '무한대'
    }
    return n.toLocaleString()
}

export const formatKoreanNumber = (value: CellProps) => {
    if (value.text != null) {
        if (value.text == 9999999999999) {
            return '무한대'
        }
        return numToKorean(value.text, FormatOptions.MIXED)
    }
    return ''
}
