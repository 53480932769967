<template>
    <a-upload
        name="file"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        :action="uploadUrl"
        @change="handleChange"
        :before-upload="beforeUpload"
        with-credentials
    >
        <img
            v-if="value && !value.endsWith('.json')"
            :src="value"
            alt="avatar"
            style="max-width: 100px; max-height: 100px"
        />
        <Vue3Lottie
            v-else-if="value && value.endsWith('.json')"
            :animationLink="value"
            :width="100"
            :height="100"
        />
        <div v-else>
            <loading-outlined v-if="loading" />
            <plus-outlined v-else />
            <div class="ant-upload-text">Upload</div>
        </div>
    </a-upload>
    <a-form-item label="이미지 주소">
        <a-input :value="value" @change="handleInput" style="width: 500px" />
    </a-form-item>
    <a-form-item label="이미지 검색">
        <a-select
            :options="options"
            :disabled="disabled"
            show-search
            style="width: 500px"
            option-filter-prop="label"
            @change="handleSelect"
            @search="onSearch"
        >
            <template #option="{ label, image }">
                <img :src="image" :width="64" />
                &nbsp;&nbsp;{{ label }}
            </template>
        </a-select>
    </a-form-item>
    <a-form-item
        label="webp 변환"
        help="체크시 이후 등록된 이미지들은 webp로 변환됩니다. (jpeg, png만) webp로 변환된 이미지는 크기가 줄어듭니다."
    >
        <a-switch v-model:checked="webp" />
    </a-form-item>
</template>

<style>
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>

<script setup lang="ts">
import { ApiResponse, adminApi, baseUrl } from '@/fetchTemplate'
import { computed, ref } from 'vue'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { fileToWebp } from '@/util/image'
import { OptionProps } from 'ant-design-vue/es/select'
import { stringify } from 'qs'

const props = defineProps({
    value: {
        type: String,
        default: '',
    },
    path: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    form: {
        type: Object,
    },
})
const loading = ref(false)

const uploadUrl = computed(() => {
    const params = {
        path: props.path,
        pillyzeAssetType: props.form?.pillyzeAssetType,
    }
    return `${baseUrl}/api/file?${stringify(params)}`
})

const options = ref<Array<OptionProps>>([])

const emits = defineEmits(['update:value'])

const handleInput = (event: any) => {
    emits('update:value', event.target.value)
}

const handleSelect = (value: any) => {
    emits('update:value', value)
}

const handleChange = async (info: any) => {
    if (info.file.status === 'uploading') {
        loading.value = true
        return
    }
    if (info.file.status === 'done') {
        const allDone = !info.fileList.some((f: any) => f.status !== 'done')
        if (allDone) {
            emits('update:value', info.file.response.result)
            loading.value = false
        }
    }
}

const onSearch = async (value: string) => {
    if (value) {
        const response = await adminApi<ApiResponse<Array<OptionProps>>>(
            `/api/pillyze-image-asset/name/${value}`
        )
        if (response.result) {
            options.value = [...response.result]
        }
    }
}

const webp = ref(true)

const beforeUpload = (file: any) => {
    if (
        webp.value === true &&
        (file.type === 'image/jpeg' || file.type === 'image/png')
    ) {
        return fileToWebp(file)
    }
    return file
}
</script>
