<template>
    <modal-button
        v-bind="$props"
        ref="modalButtonRef"
        @submit="patch"
        @after-show="afterShow"
    >
        <template #icon="{ showModal }">
            <edit-outlined @click="showModal" />
        </template>
        <template #button> 수정 </template>
        <template #header="data">
            <slot name="header" v-bind="data" />
        </template>
        <template #footer="data">
            <slot name="footer" v-bind="data" />
        </template>
    </modal-button>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import ModalButton from '@/components/modal/ModalButton.vue'
import { defineComponent, ref } from 'vue'
import { EditOutlined } from '@ant-design/icons-vue'
import globalFormState from '@/components/globalFormState'

export default defineComponent({
    name: 'UpdateFormModal',
    extends: ModalButton,
    components: {
        ModalButton,
        EditOutlined,
    },
    props: {
        inputSpec: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const modalButtonRef = ref()
        return { ...globalFormState, modalButtonRef }
    },
    methods: {
        async patch() {
            const { toggleLoading, setErrorMessage, alertFail } =
                this.modalButtonRef
            toggleLoading()

            try {
                const response = await adminApi<ApiResponse<void>>(
                    `${this.uri}/${this.resourceId}`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(this.form),
                    }
                )

                if (
                    response.success === undefined ||
                    response.success === false
                ) {
                    setErrorMessage(
                        response.errorMessage ||
                            '예외 발생 - 개발자에게 문의하세요'
                    )
                } else {
                    this.$emit('afterSubmit')
                    this.resetForm()
                    this.fetchResource()
                    toggleLoading()
                }
            } catch (error) {
                const err = error as Error
                toggleLoading()
                setErrorMessage(err.message)
                alertFail()
                throw error
            }
        },

        async fetchResource() {
            const { toggleLoading } = this.modalButtonRef
            toggleLoading()
            const response = await adminApi<ApiResponse<Record<string, any>>>(
                `${this.uri}/${this.resourceId}`
            )
            const resource = response.result

            if (resource != null) {
                this.setFormValues(resource)
            }
            toggleLoading()
        },

        afterShow() {
            this.fetchResource().then()
            this.$emit('afterShow')
        },
    },
})
</script>
