import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3520a2a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","align-items":"center","justify-content":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_reload_outlined = _resolveComponent("reload-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_minus_outlined = _resolveComponent("minus-outlined")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: "80%",
    height: "80%",
    open: _ctx.isOpen,
    "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isOpen) = $event)),
    title: "이벤트 CRM",
    "cancel-text": "취소",
    "ok-text": "발송",
    "ok-button-props": { disabled: !_ctx.isTestDone },
    onCancel: _ctx.closeModal,
    onOk: _ctx.clickSend
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_spin, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_descriptions, {
                    layout: "horizontal",
                    column: 1,
                    bordered: true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_descriptions_item, { label: "발송 수단" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.type), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "템플릿" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 16 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    style: {"width":"100%"},
                                    options: _ctx.options,
                                    value: _ctx.templateId,
                                    onSelect: _ctx.selectTemplate
                                  }, null, 8, ["options", "value", "onSelect"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_col, { span: 8 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    onClick: _withModifiers(_ctx.refreshOptions, ["prevent"]),
                                    style: {"margin-left":"5px"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_reload_outlined)
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"]),
                                  _createVNode(_component_a_button, {
                                    onClick: _withModifiers(_ctx.resetValue, ["prevent"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_minus_outlined)
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.type === 'KAKAO_TALK')
                        ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                            key: 0,
                            label: "비즈엠 템플릿 Id"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                disabled: "",
                                value: _ctx.bizmTemplateId,
                                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bizmTemplateId) = $event))
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.type === 'SMS')
                        ? (_openBlock(), _createBlock(_component_a_descriptions_item, {
                            key: 1,
                            label: "제목"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.smsTitle,
                                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.smsTitle) = $event))
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_a_descriptions_item, { label: "본문" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_textarea, {
                                    style: {"width":"100% !important","min-height":"350px"},
                                    "max-length": 10,
                                    value: _ctx.smsTemplate,
                                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.smsTemplate) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "치환변수" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.replacements, (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_descriptions, {
                              layout: "horizontal",
                              column: 2,
                              bordered: true,
                              key: index
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_descriptions_item, { label: "변수" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: item?.key,
                                      disabled: true
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_descriptions_item, { label: "치환값" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: item?.value,
                                      onChange: (e) => _ctx.update(index, 'value', e)
                                    }, null, 8, ["value", "onChange"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "비즈엠 버튼" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bizmButtons, (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_descriptions, {
                              layout: "horizontal",
                              column: 2,
                              bordered: true,
                              key: index
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_descriptions_item, { label: "버튼명" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: item.name,
                                      disabled: true
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_descriptions_item, { label: "버튼타입" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: item?.type,
                                      disabled: true
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_descriptions_item, { label: "android" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: item?.schema_android,
                                      onChange: (e) => _ctx.updateButtons(index, 'schema_android', e)
                                    }, null, 8, ["value", "onChange"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_descriptions_item, { label: "ios" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: item?.schema_ios,
                                      onChange: (e) => _ctx.updateButtons(index, 'schema_ios', e)
                                    }, null, 8, ["value", "onChange"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_descriptions_item, { label: "mobile" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: item?.url_mobile,
                                      onChange: (e) => _ctx.updateButtons(index, 'url_mobile', e)
                                    }, null, 8, ["value", "onChange"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_descriptions_item, { label: "pc" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: item?.url_pc,
                                      onChange: (e) => _ctx.updateButtons(index, 'url_pc', e)
                                    }, null, 8, ["value", "onChange"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "테스트 발송(userId)" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, { style: {"padding-bottom":"5px"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input_number, {
                                    class: "input-number",
                                    value: _ctx.testUserId,
                                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.testUserId) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                span: 12,
                                style: {"padding-right":"5px"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    warning: "",
                                    class: "btn btn-send-test",
                                    onClick: _withModifiers(_ctx.clickSendToMe, ["prevent"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" 테스트 발송 ")
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_col, {
                                span: 12,
                                style: {"padding-left":"5px"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    class: "btn btn-check-test",
                                    onClick: _withModifiers(_ctx.checkSendTest, ["prevent"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" 발송 확인 ")
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, { style: {"padding":"10px"} }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.responseMessage), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "수신인 리스트" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                span: 24,
                                class: "event-user-label"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventUserList, (eventUserId) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      class: "event-user-id",
                                      key: eventUserId
                                    }, _toDisplayString(eventUserId), 1))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open", "ok-button-props", "onCancel", "onOk"]))
}